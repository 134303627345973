import React from "react";
import { Box, CircularProgress } from "@mui/material";
import EnhancedTable from "../../common/Table/EnhancedTable";
import PagePlaceholder from "../../common/PagePlaceholder.jsx/PagePlaceholder";

const headCells = [
  { id: "lineItem", label: "" },
  { id: "brand", label: "Brand" },
  { id: "internalName", label: "Internal Name" },
  { id: "unitsOrdered", label: "Units Ordered" },
  { id: "price", label: "Price" },
  { id: "extwholesale", label: "EXT. Wholesale" },
];

function OrderTablePDF({ handleViewDetail, isLoading, orderLines }) {
  if (isLoading)
    return (
      <PagePlaceholder img={<CircularProgress />} text="Loading orders..." />
    );

  const rows = orderLines?.map((line, index) => ({
    cells: {
      number: {
        rawValue: index + 1,
        content: index + 1,
      },
      brand: {
        rawValue: line.brand,
        content: line.brand.name,
      },
      internalName: {
        rawValue: line.item.name,
        content: line.item.name,
      },
      unitsOrdered: {
        rawValue: parseFloat(line.quantity),
        content: parseFloat(line.quantity),
      },
      price: {
        rawValue: parseFloat(line.price),
        content: parseFloat(line.price),
      },
      extwholesale: {
        rawValue: (parseFloat(line.quantity) * parseFloat(line.price)).toFixed(
          2
        ),
        content: (parseFloat(line.quantity) * parseFloat(line.price)).toFixed(
          2
        ),
      },
    },
  }));

  return (
    <main>
      {orderLines?.length > 0 ? (
        <EnhancedTable rows={rows} headCells={headCells} mobileVariant={true} />
      ) : (
        <Box bgcolor="background.paper" borderRadius={1}>
          <PagePlaceholder text="No entries found" />
        </Box>
      )}
    </main>
  );
}

export default OrderTablePDF;
