import { useQuery } from "@apollo/client";
import { GET_BRANDS } from "../../../graphql/brands/query";


export const useBrands = (vendorId = null, name = "") => {
  // const [brands, setBrands] = useState([]);
  const { data, loading, error } = useQuery(GET_BRANDS, {
    variables: {
      filter: {
        name,
        vendorId,
      },
    },
    onCompleted: (data) => {
      // if (data?.brands) {
      //   setBrands(data.brands);
      // }
    },
  });

  // const getBrand = (id) => {
  //   return brands.find((brand) => brand.id === id);
  // };

  return {
    brands: data?.brands,
    loading,
    error,
  };
};
