import { Button, Typography, Box } from "@mui/material";
import { styled } from "@mui/material/styles";

const StyledErrorFallback = styled(Box)`
  height: 100vh;
  background-color: var(--color-grey-50);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4.8rem;
`;

const StyledBox = styled(Box)`
  /* Box */
  background-color: var(--color-grey-0);
  border: 1px solid var(--color-grey-100);
  border-radius: var(--border-radius-md);

  padding: 4.8rem;
  flex: 0 1 96rem;
  text-align: center;

  & h1 {
    margin-bottom: 1.6rem;
  }

  & p {
    font-family: "Sono";
    margin-bottom: 3.2rem;
    color: var(--color-grey-500);
  }
`;
function ErrorFallback({ error, resetErrorBoundary }) {
  return (
    <>
      <StyledErrorFallback>
        <StyledBox>
          <Typography as="h1">Something went wrong 🧐</Typography>
          <p>{error.message}</p>
          <Button size="large" onClick={resetErrorBoundary}>
            Try again
          </Button>
        </StyledBox>
      </StyledErrorFallback>
    </>
  );
}

export default ErrorFallback;
