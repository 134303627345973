import React from "react";
import { FormProvider, useForm, Controller } from "react-hook-form";
import {
  TextField,
  Button,
  Box,
  ButtonGroup,
  CircularProgress,
} from "@mui/material";
import { LOGIN } from "../graphql/users/mutation";
import { useMutation } from "@apollo/client";
import { useNotification } from "../services/hooks/useNotification";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../context/AuthContext";

function LoginPage() {
  const {
    handleSubmit,
    control,
    setError,
    methods,
    formState: { errors },
  } = useForm();
  const navigate = useNavigate();
  const [login, { loading }] = useMutation(LOGIN);
  const { handleLogin } = useAuth();
  const { notify } = useNotification();

  const onSubmit = async (data) => {
    const { username, password } = data;
    try {
      const res = await login({ variables: { username, password } });
      handleLogin(res?.data?.login);
    } catch (error) {
      console.log(error);
      notify(error?.message, "error");
    } finally {
      navigate("/orders");
    }
  };

  return (
    <FormProvider {...methods}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          height: "90vh",
        }}
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              gap: "10px",
            }}
          >
            <Controller
              name="username"
              control={control}
              rules={{ required: "Username is required" }}
              render={({ field }) => (
                <TextField
                  {...field}
                  error={Boolean(errors.username && errors.username.message)}
                  sx={{ width: "300px" }}
                  label="Username"
                  helperText={errors.username?.message}
                />
              )}
            />
            <Controller
              name="password"
              control={control}
              rules={{ required: "Password is required" }}
              render={({ field }) => (
                <TextField
                  {...field}
                  sx={{ width: "300px" }}
                  label="Enter password"
                  type="password"
                  error={Boolean(errors.password && errors.password.message)}
                  helperText={errors.password?.message}
                />
              )}
            />
            <ButtonGroup>
              <Button
                endIcon={loading ? <CircularProgress size={20} /> : null}
                type="submit"
              >
                Login
              </Button>
              {/* <Button href="/register">Register</Button> */}
            </ButtonGroup>
          </Box>
        </form>
      </Box>
    </FormProvider>
  );
}

export default LoginPage;
