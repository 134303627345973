import { gql } from "@apollo/client";
import { Brand } from "./fragment";

export const GET_BRANDS = gql`
  query getBrands($filter: BrandFilterInput) {
    brands(filter: $filter) {
      ...Brand
    }
  }
  ${Brand}
`;
