import React, { useState } from "react";
import { Button, SvgIcon, Grid, TextField, Divider } from "@mui/material";
import PageHeader from "../../common/PageHeader/index";
import { useNavigate } from "react-router-dom";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
// import ExportIcon from "../../../assets/images/common/export.svg";
import InputAdornment from "@mui/material/InputAdornment";

import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Search from "@mui/icons-material/Search";

const HeaderButton = ({ icon, onClick, disabled, children }) => {
  const svg = <SvgIcon component={icon} inheritViewBox fontSize="small" />;

  return (
    <Button
      onClick={onClick}
      disabled={disabled}
      variant="contained"
      color="primary"
      size="small"
      startIcon={svg}
    >
      {children}
    </Button>
  );
};

function OrderHeader({
  handleOpenModal,
  handleOpenModalWithValues,
  allOrders,
  searchQuery,
  onSearchQueryChange,
}) {
  const [showFilter, setShowFilter] = useState(false);
  const navigate = useNavigate();
  const toggleFilter = () => {
    setShowFilter((prev) => !prev);
  };

  const canExport = Object.values(allOrders).some(
    (budget) => Object.keys(budget || {}).length > 0
  );

  const ExpandIcon = showFilter ? ExpandLessIcon : ExpandMoreIcon;
  return (
    <>
      <PageHeader
        title=""
        noBorder={false}
        action={
          <Grid container spacing={1} justifyContent={["space-between", ""]}>
            <Grid item xs={12} md={5} xl={4.5} display={"flex"} gap={1}>
              <TextField
                placeholder="Search orders..."
                variant="filled"
                hiddenLabel
                value={searchQuery}
                onChange={(e) => {
                  onSearchQueryChange(e.target.value);
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Search />
                    </InputAdornment>
                  ),
                  disableUnderline: true,
                }}
                fullWidth
                size="small"
              />
              <Button
                variant="outlined"
                sx={{
                  padding: "0 1rem",
                }}
                onClick={toggleFilter}
                size="small"
                endIcon={<ExpandIcon />}
              >
                Filter
              </Button>
            </Grid>
            <Grid
              item
              xs={12}
              md={5}
              xl={4.5}
              display="flex"
              gap={1}
              justifyContent={["space-between", "flex-end"]}
              alignItems="flex-end"
            >
              {/* <HeaderButton
                // onClick={handleOpenModal(ModalType.EXPORT)}
                icon={ExportIcon}
                disabled={!canExport}
              >
                CSV Report
              </HeaderButton> */}
              <HeaderButton
                onClick={() => navigate("/order/create")}
                icon={AddCircleOutlineIcon}
              >
                Create Order
              </HeaderButton>
            </Grid>
          </Grid>
        }
      />
      {/* {showFilter && <BudgetFilterForm toggleFilter={toggleFilter} />} */}
    </>
  );
}

export default OrderHeader;

// export default madProps(OrderHeader, {
//   allOrders: useAllBudgets,
// })
