import React, { useEffect, useState } from "react";
import LinesAutoComplete from "../common/LinesAutocomplete";
import { useFormContext } from "react-hook-form";
import { useItems } from "./hooks/useItems";
import Loader from "../common/Loader.jsx";
function ItemAutocomplete({ index }) {
  const [brandId, setBrandId] = useState(null);
  const { items, loading } = useItems(brandId);
  const [options, setOptions] = useState([]);
  const { watch } = useFormContext();

  useEffect(() => {
    const subscription = watch((value) => {
      setBrandId(value.lines[index].brand);
    });
    return () => subscription.unsubscribe();
  }, [watch, index]);

  useEffect(() => {
    if (!loading && items) {
      setOptions(items);
    }
  }, [items, loading]);
  return (
    <LinesAutoComplete
      rules={{
        required: {
          value: true,
          message: "*",
        },
      }}
      index={index}
      label={"Item"}
      options={options ?? []}
      loading={loading}
      inputProps={{
        endAdornment: (
          <React.Fragment>
            {loading ? <Loader color="inherit" size={10} /> : null}
          </React.Fragment>
        ),
      }}
    />
  );
}

export default ItemAutocomplete;
