import React, { useState } from "react";
import { Button, SvgIcon, Grid, TextField, Modal } from "@mui/material";
import PageHeader from "../PageHeader/index";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import InputAdornment from "@mui/material/InputAdornment";
import { ModalType } from "../../../utils/contants/Modal/modal";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Search from "@mui/icons-material/Search";

const HeaderButton = ({ icon, onClick, disabled, children }) => {
  const svg = <SvgIcon component={icon} inheritViewBox fontSize="medium" />;

  return (
    <Button
      onClick={onClick}
      disabled={disabled}
      variant="contained"
      color="primary"
      size="small"
      startIcon={svg}
    >
      {children}
    </Button>
  );
};

function ListHeader({
  handleOpenModal,
  handleOpenModalWithValues,
  title,
  allListItems,
  searchQuery,
  onSearchQueryChange,
}) {
  const [showFilter, setShowFilter] = useState(false);
  const toggleFilter = () => {
    setShowFilter((prev) => !prev);
  };

  const canExport = Object.values(allListItems ?? {}).some(
    (list) => Object.keys(list || {}).length > 0
  );

  const ExpandIcon = showFilter ? ExpandLessIcon : ExpandMoreIcon;
  return (
    <>
      <PageHeader
        title={title}
        noBorder={false}
        action={
          <Grid container spacing={1} justifyContent={["space-between", ""]}>
            <Grid item xs={12} md={5} xl={4.5} display={"flex"} gap={1}>
              <TextField
                placeholder="Search"
                variant="filled"
                hiddenLabel
                value={searchQuery}
                onChange={(e) => {
                  onSearchQueryChange(e.target.value);
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Search />
                    </InputAdornment>
                  ),
                  disableUnderline: true,
                }}
                fullWidth
                size="small"
              />
              <Button
                variant="outlined"
                sx={{
                  padding: "0 1rem",
                }}
                onClick={toggleFilter}
                size="small"
                endIcon={<ExpandIcon />}
              >
                Filter
              </Button>
            </Grid>
            <Grid
              item
              xs={12}
              md={5}
              xl={4.5}
              display="flex"
              gap={1}
              justifyContent={["space-between", "flex-end"]}
              alignItems="flex-end"
            >
              <HeaderButton
                onClick={() => handleOpenModalWithValues(ModalType.EXPORT, allListItems)}
                icon={AddCircleOutlineIcon}
                disabled={!canExport}
              >
                CSV Report
              </HeaderButton>
              <HeaderButton
                onClick={() => handleOpenModalWithValues(ModalType.ENTRY, "")}
                icon={AddCircleOutlineIcon}
              >
                {`Create ${title}`}
              </HeaderButton>
            </Grid>
          </Grid>
        }
      />
      {/* {showFilter && <BudgetFilterForm toggleFilter={toggleFilter} />} */}
    </>
  );
}

export default ListHeader;

// export default madProps(ListHeader, {
//   allListItems: useAllBudgets,
// })
