import * as React from "react";
import Chip from "@mui/material/Chip";
import useMediaQuery from "@mui/material/useMediaQuery";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import { useTheme } from "@mui/material/styles";

export const StatusTypes = {
  PAID: "success.main", // green
  UNPAID: "error.main", // red
  CLOSED: "success.main", // green
  SUCCESS: "success.light", // green
  SIGNED: "success.main", // green
  FAILED: "error.main", // failedRed
  PENDING: "warning.light", // pendingYellow
  PROCESSING: "info.main", // processingBlue
  CANCELLED: "error.main", // cancelledRed
  COMPLETED: "success.main", // completedGreen
  IN_PROGRESS: "info.main", // inProgressBlue
  IN_REVIEW: "warning.main", // inReviewYellow
  APPROVED: "success.main", // success
  REJECTED: "error.main", // rejectedRed
  PENDING_APPROVAL: "warning.main", // pendingApprovalYellow
  NEW: "error.main", // red
};

const StatusChip = ({ status, type }) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery("(max-width: 600px)");

  status = status.toUpperCase();

  if (status in StatusTypes) {
    const statusColour = StatusTypes[status].split(".");
    return (
      <Chip
        sx={{
          backgroundColor:
            theme.palette[statusColour[0]][statusColour[1]] ||
            theme.palette.error.main,
          color: theme.palette[statusColour[0]].contrastText,
        }}
        icon={
          <CheckCircleOutlineIcon
            fontSize="small"
            color={theme.palette[statusColour[0]].contrastText}
          />
        }
        label={isSmallScreen ? "" : status}
      />
    );
  }

  return null;
};

export default StatusChip;
