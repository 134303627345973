import { useState, useMemo } from "react";
import Lists from "../../common/Lists";
import ListHeader from "../../common/Lists/ListsHeader";
import CreateItem from "./CreateItem";
import ItemDetail from "./ItemDetail";
import ExportDialog from "../../common/Lists/ExportDialog";
import { ModalType, defaultOpen } from "../../../utils/contants/Modal/modal";
import { useItems } from "../hooks/useItems";
import FullPageSpinner from "../../common/FullPage";

const coloumnsForCsvExport = ["name", "size", "strain", "description", "category"];

export default function VendorList() {
  const { items, loading } = useItems();
  const [searchQuery, setSearchQuery] = useState("");
  const [defaultValues, setDefaultValues] = useState(undefined);
  const [open, setOpen] = useState(defaultOpen);

  const filteredEntries = useMemo(() => {
    if (!searchQuery) {
      return items;
    }

    const query = searchQuery.toLowerCase();
    return items.filter((order) => {
      const { name } = order;

      return name.toLowerCase().includes(query);
    });
  }, [items, searchQuery]);

  const handleClose = () => {
    setOpen(defaultOpen);
    setDefaultValues(undefined);
  };
  const handleOpenModal = (type) => () => {
    setOpen((prev) => ({ ...prev, [type]: true }));
  };
  const handleOpenModalWithValues = (modal, data) => {
    setDefaultValues(data);
    handleOpenModal(modal)();
  };

  return (
    <>
      <ListHeader
        handleOpenModal={handleOpenModal}
        handleOpenModalWithValues={handleOpenModalWithValues}
        title="Items"
        allListItems={items}
        searchQuery={searchQuery}
        onSearchQueryChange={setSearchQuery}
      />
      {loading ? (
        <FullPageSpinner />
      ) : (
        <Lists
          handleViewDetail={(data) =>
            handleOpenModalWithValues(ModalType.DETAILS, { data })
          }
          loading={loading}
          list={filteredEntries}
          label="items"
        />
      )}

      {open[ModalType.ENTRY] && (
        <CreateItem handleClose={handleClose} defaultValues={defaultValues} />
      )}

      {open[ModalType.DETAILS] && (
        <ItemDetail handleClose={handleClose} defaultValues={defaultValues} />
      )}

      {open[ModalType.EXPORT] && (
        <ExportDialog
          handleClose={handleClose}
          defaultValues={defaultValues}
          coloumnsForCsvExport={coloumnsForCsvExport}
          name="Items"
        />
      )}
    </>
  );
}
