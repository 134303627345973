import React, { useMemo } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { TextField } from "@mui/material";

function NumberField({ name, label, rules, required = false }) {
  const {
    control,
    formState: { errors },
  } = useFormContext();

  const componenetBeingUsedInNested = useMemo(() => {
    return name.split(".");
  }, [errors]);
  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <TextField
          {...field}
          label={label}
          type="number"
          error={
            componenetBeingUsedInNested
              ? Boolean(
                  errors?.lines?.[componenetBeingUsedInNested[1]]?.[
                    label.toLowerCase()
                  ]?.message ?? false
                )
              : !!(field.label.error && field.touched)
          }
          helperText={
            componenetBeingUsedInNested
              ? errors?.lines?.[componenetBeingUsedInNested[1]]?.[
                  label?.toLowerCase()
                ]?.message ?? ""
              : field.label.error && field.touched
              ? field.error.message
              : ""
          }
          // InputLabelProps={{
          //   shrink: false, // Prevent the label from shrinking
          // }}
          value={field.value}
          onChange={(e) => {
            field.onChange(e.target.value);
          }}
          variant="outlined"
          fullWidth
          // required={required}
        />
      )}
      rules={rules}
    />
  );
}

export default NumberField;
