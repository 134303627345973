// useVendors.js
import { useState, useEffect } from "react";
import { GET_VENDORS } from "../../../graphql/vendors/query";
import useQueryWrapper from "../../common/hooks/useQueryWrapper";

export const useVendors = () => {
  const [vendors, setVendors] = useState([]);
  const { loading, error, data } = useQueryWrapper(GET_VENDORS, {
    variables: {
      filter: {
        name: "",
      },
      sort: {
        field: "name",
        order: "ASC",
      },
    },
    onCompleted: (data) => {
      if (data?.vendors) {
        setVendors(data.vendors);
      }
    },
    onError: (error) => {
      console.log(error);
    },
  });

  useEffect(() => {
    if (data?.vendors) {
      setVendors(data.vendors);
    }
  }, [data]);

  const getVendor = async (id) => {
    if (vendors.length > 0) {
      return vendors.find((vendor) => vendor.id === id);
    }
    return data?.vendors?.find((vendor) => vendor.id === id);
  };

  const getVendors = async () => {
    return vendors.length > 0 ? vendors : data?.vendors || [];
  };

  return {
    loading,
    error,
    vendors,
    getVendor,
    getVendors,
  };
};
