import { gql } from "@apollo/client";

const vendor = gql`
  fragment Vendor on Vendor {
    id
    name
    createdAt
  }
`;

export const GET_VENDORS = gql`
  query getVendors($filter: VendorFilterInput, $sort: VendorSortInput) {
    vendors(filter: $filter, sort: $sort) {
      ...Vendor
    }
  }
  ${vendor}
`;
export const GET_VENDOR = gql`
  query getVendor($id: ID!) {
    vendor(id: $id) {
      ...Vendor
    }
  }
  ${vendor}
`;
