import { styled } from "@mui/material/styles";
import { CircularProgress, Box } from "@mui/material";

const FullPage = styled(Box)`
  height: 100vh;
  background-color: var(--color-grey-50);
  display: flex;
  align-items: center;
  justify-content: center;
`;

export default function FullPageSpinner() {
  return (
    <FullPage>
      <CircularProgress />
    </FullPage>
  );
}
