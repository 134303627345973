import * as React from "react";

import {
  Box,
  CircularProgress,
  Tooltip,
  SvgIcon,
  TextField,
} from "@mui/material";
import LaunchIcon from "@mui/icons-material/Launch";
import IconButton from "@mui/material/IconButton";
import EnhancedTable from "../../common/Table/EnhancedTable";
import PagePlaceholder from "../../common/PagePlaceholder.jsx/PagePlaceholder";
import tableCss from "../../common/Table/styles.module.css";
import { Chip } from "@mui/material";
import { CheckBox, Padding } from "@mui/icons-material";
import FullPageSpinner from "../FullPage";

const isNewItem = (createdAt) => {
  const now = new Date();
  const createdDate = new Date(Number(createdAt));

  const diffTime = Math.abs(now - createdDate);
  const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
  return diffDays <= 7; // Consider items created within the last 7 days as new
};

const headCells = [
  // {
  //   id: "selected",
  //   label: <TextField type="checkbox" name="selected" value="selected" />,
  //   padding: "0px",
  // },
  { id: "name", label: "Name" },
  { id: "actions", label: "" },
];

function Lists({ handleViewDetail, isLoading, list }) {
  if (isLoading) return <FullPageSpinner />;

  const rows = list?.map((list) => ({
    cells: {
      // selected: {
      //   rawValue: "Selected",
      //   content: (
      //     <Box
      //       component="span"
      //       sx={
      //         {
      //           // display: "flex",
      //           // alignItems: "center",
      //         }
      //       }
      //     >
      //       <input
      //         type="checkbox"
      //         name="selected"
      //         value={list.id}
      //         defaultChecked={list.selected}
      //       />
      //     </Box>
      //   ),
      // },
      name: {
        rawValue: list.number,
        content: isNewItem(list.createdAt) ? (
          <>
            {list.name}
            <Chip
              label="New"
              sx={{
                ml: 1,
                backgroundColor: "green",
              }}
            />
          </>
        ) : (
          list.name
        ),
      },
      actions: {
        rawValue: "Actions",
        content: (
          <div className={tableCss.actions}>
            <Tooltip title="View Details" placement="top">
              <IconButton
                onClick={() => {
                  handleViewDetail(list);
                }}
                size="small"
              >
                <SvgIcon
                  component={LaunchIcon}
                  inheritViewBox
                  sx={{ color: "icon.main" }}
                  fontSize="small"
                />
              </IconButton>
            </Tooltip>
          </div>
        ),
      },
    },
  }));

  return (
    <main>
      {list?.length > 0 ? (
        <EnhancedTable rows={rows} headCells={headCells} mobileVariant />
      ) : (
        <Box bgcolor="background.paper" borderRadius={1}>
          <PagePlaceholder text="No entries found" />
        </Box>
      )}
    </main>
  );
}

export default React.memo(Lists);
