import React from "react";
import { useCategories } from "./hooks/useCategories.jsx";
import AutoComplete from "../common/AutoComplete";

function CategoryAutoComplete() {
  const { categories, loading } = useCategories();

  return (
    <AutoComplete
      loading={loading}
      data={categories}
      label="Category"
      name="category"
      rules={{
        required: {
          value: true,
          message: "*",
        },
      }}
    />
  );
}

export default CategoryAutoComplete;
