import { useMutation } from "@apollo/client";
import { CREATE_BRAND } from "../../../graphql/brands/mutation";
import { GET_BRANDS } from "../../../graphql/brands/query";
import { useNotification } from "../../../services/hooks/useNotification";
import useAuthStore from "../../../store/authStore";

export const useCreateBrand = (handleClose) => {
  const { currentStore} = useAuthStore();
  const { notify } = useNotification();
  const [createBrand, { loading, error }] = useMutation(CREATE_BRAND, {
    onCompleted: () => {
      notify("Brand added successfully", "success");
      handleClose();
    },
    onError: (error) => {
      console.log(error);
      notify(`Failed to add brand: ${error.message}`, "error");
    },
    refetchQueries: [
      GET_BRANDS, // DocumentNode object parsed with gql
      "GetComments", // Query name
    ],
  });

  const addBrand = async (input) => {
    const { data } = await createBrand({
      variables: {
        input: {
          name: input.name,
          vendorId: input.vendor,
          storeId: currentStore?.id,
        },
      },
    });
  };
  return { addBrand, loading, error };
};
