import React from "react";
import { Typography } from "@mui/material";
import DetailModal from "../../common/Lists/DetailModal";

function ItemDetail({ handleClose, defaultValues }) {
  return (
    <DetailModal title="Item Detail" handleClose={handleClose}>
      <Typography variant="label">Name: {defaultValues.data.name}</Typography>
      <Typography variant="label">
        Category: {defaultValues.data?.category?.name}
      </Typography>
      <Typography variant="label">Size: {defaultValues.data.size}</Typography>
      <Typography variant="label">
        Strain: {defaultValues.data.strain}
      </Typography>
      <Typography variant="label">
        Description: {defaultValues.data.description}
      </Typography>
    </DetailModal>
  );
}

export default ItemDetail;
