import useQueryWrapper from "../../common/hooks/useQueryWrapper";
import { GET_ORDERS } from "../../../graphql/order/query";

export const useGetOrders = (currentStoreId) => {
  const { loading, error, data } = useQueryWrapper(GET_ORDERS, {
    variables: {
      filter: {
        storeId: currentStoreId,
      },
      sort: {
        field: "name",
        order: "ASC",
      },
    },
  });

  // Return the necessary variables and functions
  return { loading, error, orders: data?.orders };
};
