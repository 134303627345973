import { useMutation } from "@apollo/client";
import { useNotification } from "../../../../services/hooks/useNotification";
import { gql } from "@apollo/client";
import { CREATE_MASTER_CATEGORY } from "../../../../graphql/categories/mutation";

export const useMutateMasterCategory = (handleClose) => {
  const { notify } = useNotification();
  const [createMasterCategory, { loading }] = useMutation(
    CREATE_MASTER_CATEGORY,
    {
      onCompleted: () => {
        handleClose();
        notify("Master Category created successfully");
      },
      onError: (error) => {
        notify(error.message);
      },
      update: (cache, { data: { createMasterCategory } }) => {
        cache.modify({
          fields: {
            masterCategories(existingMasterCategories = []) {
              const newMasterCategoryRef = cache.writeFragment({
                data: createMasterCategory,
                fragment: gql`
                  fragment NewMasterCategory on MasterCategory {
                    id
                    name
                    createdAt
                  }
                `,
              });
              return [...existingMasterCategories, newMasterCategoryRef];
            },
          },
        });
      },
    }
  );
  const addMasterCategory = async (data) => {
    try {
      await createMasterCategory({
        variables: {
          input: {
            name: data.name,
          },
        },
      });
    } catch (e) {
      console.log(e);
    }
  };
  return { addMasterCategory, loading };
};
