import React, { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import MuiDrawer from "@mui/material/Drawer";
import Box from "@mui/material/Box";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import LeaderboardIcon from "@mui/icons-material/Leaderboard";
import SettingsIcon from "@mui/icons-material/Settings";
import { mainListItems, secondaryListItems } from "../NavItems";
import { Autocomplete, Button, TextField } from "@mui/material";
import { useAuth } from "../../../context/AuthContext";
import { useLocation } from "react-router-dom";
import { ChevronRight } from "@mui/icons-material";
import useAuthStore from "../../../store/authStore";
const drawerWidth = 200;

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  "& .MuiDrawer-paper": {
    position: "relative",
    whiteSpace: "nowrap",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    // boxSizing: "border-box",
    ...(!open && {
      overflowX: "auto",
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      width: theme.spacing(7),
      [theme.breakpoints.up("sm")]: {
        width: theme.spacing(9),
      },
    }),
  },
}));

export default function Navigation({ children }) {
  const location = useLocation();
  const { currentStore, stores, user, logout } = useAuthStore();
  const { handleStoreChange } = useAuth();

  const [open, setOpen] = useState(true);
  const toggleDrawer = () => {
    setOpen(!open);
  };

  return (
    <Box sx={{ display: "flex" }}>
      <AppBar open={open}>
        <Toolbar
          sx={{
            pr: "24px", // keep right padding when drawer closed
          }}
        >
          <IconButton
            edge="start"
            aria-label="open drawer"
            onClick={toggleDrawer}
            sx={{
              marginRight: "36px",
              ...(open && { display: "none" }),
            }}
          >
            <MenuIcon />
          </IconButton>
          <Typography
            component="h1"
            variant="caption"
            color="inherit"
            noWrap
            sx={{ flexGrow: 1 }}
          >
            {location.pathname.split("/")[1].toUpperCase()}
          </Typography>
          <Autocomplete
            id="current-store"
            hiddenLabel
            disabled={(stores.length === 1 && stores[0].id === currentStore?.id) || stores.length === 0}
            options={stores || []}
            sx={{
              padding: "0px",
            }}
            getOptionLabel={(option) => option.name}
            getOptionDisabled={(option) =>
              option === currentStore ? true : false
            }
            value={currentStore}
            onChange={(event, newValue) => {
              if (newValue) {
                handleStoreChange(newValue);
              }
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                hiddenLabel
                variant="filled"
                placeholder="Select Store"
                sx={{
                  width: "15rem",
                  margin: "0rem 1rem",
                  "& .MuiFilledInput-root.MuiInputBase-hiddenLabel.MuiInputBase-sizeSmall .MuiAutocomplete-input":
                    {
                      padding: "4px 8px",
                    },
                }}
                disabled={
                  stores.length === 1 && stores[0].id === currentStore?.id
                }
              />
            )}
          />
          <Button
            variant="contained"
            onClick={
              user !== undefined
                ? () => logout() && localStorage.clear()
                : () => {}
            }
          >
            {user !== undefined ? "Logout" : "Login"}
          </Button>
        </Toolbar>
      </AppBar>
      <Drawer variant="permanent" open={open}>
        <Toolbar
          sx={{
            display: "flex",
            alignItems: "center",
            px: [1],
            marginLeft: [1],
          }}
        >
          <Button
            startIcon={<LeaderboardIcon />}
            endIcon={<ChevronRight onClick={toggleDrawer} />}
          >
            {currentStore?.name}
          </Button>
        </Toolbar>
        <Divider />
        <List component="nav">
          {mainListItems}
          <Divider color="primary" sx={{ my: 1 }} />
          {secondaryListItems}
        </List>
      </Drawer>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          width: "100%",
          marginTop: 5,
        }}
      >
        {/* <Toolbar /> */}
        {children}
      </Box>
    </Box>
  );

}