import { gql } from "@apollo/client";

export const CREATE_CATEGORY = gql`
  mutation CreateCategory($input: CreateCategoryInput!) {
    createCategory(input: $input) {
      id
      name
      createdAt
    }
  }
`;

export const CREATE_MASTER_CATEGORY = gql`
  mutation CreateMasterCategory($input: CreateMasterCategoryInput!) {
    createMasterCategory(input: $input) {
      id
      name
      createdAt
    }
  }
`;
