import { gql } from "@apollo/client";

export const CREATE_ITEM = gql`
  mutation createItem($input: CreateItemInput!) {
    createItem(input: $input) {
      id
      name
      description
      size
      strain
      store {
        id
      }
      category {
        id
        name
      }
    }
  }
`;
