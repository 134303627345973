import { useMutation } from "@apollo/client";

import { CREATE_CATEGORY } from "../../../graphql/categories/mutation";
import { GET_CATEGORIES } from "../../../graphql/categories/query";
import { useNotification } from "../../../services/hooks/useNotification";

export const useMutateCategory = (handleClose) => {
  const { notify } = useNotification();
  const [createCategory, { loading }] = useMutation(CREATE_CATEGORY, {
    refetchQueries: [GET_CATEGORIES],
    onCompleted: () => {
      notify("Category created successfully", "success");
      handleClose();
    },
    onError: () => {
      notify("Error creating category", "error");
    },
    update(cache, { data: { createCategory } }) {
      const { categories } = cache.readQuery({
        query: GET_CATEGORIES,
        variables: {
          filter: {
            name: "",
          },
          sort: {
            field: "name",
            order: "ASC",
          },
        },
      });
      cache.writeQuery({
        query: GET_CATEGORIES,
        data: {
          categories: [...categories, createCategory],
        },
        variables: {
          filter: {
            name: "",
          },
          sort: {
            field: "name",
            order: "ASC",
          },
        },
      });
    },
  });

  const addCategory = (data) => {
    createCategory({
      variables: {
        input: {
          ...data,
        },
      },
    });
  };

  return { addCategory, loading };
};
