import { useQuery } from "@apollo/client";
import { useNotification } from "../../../services/hooks/useNotification";

const useQueryWrapper = (query, options = {}) => {
  const { notify } = useNotification();

  return useQuery(query, {
    ...options,
    onError: (error) => {

      if (error.graphQLErrors) {
        error.graphQLErrors.forEach((err) => {
          if (err.extensions?.code === "UNAUTHENTICATED") {
            notify({
              message: "Session expired. Please log in again.",
              type: "error",
            });
          } else {
            notify({
              message: `Error: ${err.message}`,
              type: "error",
            });
          }
        });
      } else if (error.networkError) {
        notify({
          message: "Network error. Please check your connection.",
          type: "error",
        });
      }

      if (options.onError) {
        options.onError(error);
      }
    },
  });
};

export default useQueryWrapper;
