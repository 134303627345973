import { DialogActions, DialogContent, Typography } from "@mui/material";
import ModalDialog from "../../common/ModalDialog/index";

function VendorDetail({ handleClose, defaultValues }) {
  return (
    <ModalDialog open onClose={handleClose} dialogTitle="Vendor Details">
      <DialogContent>
        <Typography variant="label">Name: {defaultValues.data.name}</Typography>
      </DialogContent>

      <DialogActions
        sx={{
          display: "flex",
          margin: 1,
        }}
      ></DialogActions>
    </ModalDialog>
  );
}

export default VendorDetail;
