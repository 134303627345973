import { gql } from "@apollo/client";

export const GET_CATEGORIES = gql`
  query GetCategories {
    categories {
      id
      name
      createdAt
      masterCategory {
        id
        name
      }
    }
  }
`;

export const GET_MASTER_CATEGORIES = gql`
  query GetMasterCategories {
    masterCategories {
      id
      name
      createdAt
    }
  }
`;
