import { gql } from "@apollo/client";
export const Brand = gql`
  fragment Brand on Brand {
    id
    name
    vendorId
    companyId
    store {
      id
    }
    vendor {
      id
      name
    }
  }
`;
