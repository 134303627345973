import React, { useEffect, useState } from "react";
import LinesAutoComplete from "../common/LinesAutocomplete.jsx";
import { useBrands } from "./hooks/useBrands.jsx";
import { useFormContext } from "react-hook-form";
import Loader from "../common/Loader.jsx";
function BrandsLinesAutocomplete({ index }) {
  const [vendorId, setVendorId] = useState(null);
  const { brands, loading } = useBrands(vendorId);
  const [options, setOptions] = useState([]);
  const { watch, setValue, getValues } = useFormContext();

  useEffect(() => {
    const subscription = watch((value) => {
      setVendorId(value.vendor);
    });
    return () => subscription.unsubscribe();
  }, [watch]);

  // const vendor = watch("vendor");

  // useEffect(() => {
  //   if (vendorId !== null) {
  //     // Reset the brand field for all lines when vendorId changes
  //     const lines = getValues("lines");
  //     console.log("lines", lines);
  //     lines.forEach((field, lineIndex) => {
  //       console.log(field);
  //       setValue(`${field}.brand`, null);
  //     });
  //   }
  // }, [vendorId, setValue, getValues, vendor]);

  useEffect(() => {
    if (!loading && brands) {
      setOptions(brands);
    }
  }, [brands, loading]);

  // console.log("vendorId:", vendorId);
  // console.log(getValues("lines"));
  return (
    <LinesAutoComplete
      rules={{
        required: {
          value: true,
          message: "*",
        },
      }}
      index={index}
      label={"Brand"}
      disabled={loading || vendorId === null || vendorId === undefined}
      options={options ?? []}
      loading={loading}
      inputProps={{
        endAdornment: (
          <React.Fragment>
            {loading ? <Loader color="inherit" size={10} /> : null}
          </React.Fragment>
        ),
      }}
    />
  );
}

export default BrandsLinesAutocomplete;
