import { useQuery } from "@apollo/client";
import { useState, useEffect } from "react";

import { GET_MASTER_CATEGORIES } from "../../../../graphql/categories/query";

export const useMasterCategories = () => {
  const [masterCategories, setMasterCategories] = useState([]);
  const { data, loading, error } = useQuery(GET_MASTER_CATEGORIES, {
    onCompleted: (data) => {
      if (data.masterCategories) {
        setMasterCategories(data.masterCategories);
      }
    },
  });

  useEffect(() => {
    if (data && data.masterCategories) {
      // const vendors = data.vendors.map((vendor) => {
      //   const formattedCreatedAt = formatDate(vendor.createdAt);
      //   console.log(formattedCreatedAt);
      //   vendor.isNew = formattedCreatedAt;
      //   return vendor;
      // });
      setMasterCategories(data.masterCategories);
    } else {
      setMasterCategories([]);
    }
  }, [data]);
  return { masterCategories, loading, error };
};
