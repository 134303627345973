import { gql } from "@apollo/client";

import { Order } from "./fragment";

export const GET_ORDERS = gql`
  query orders($filter: OrderFilterInput) {
    orders(filter: $filter) {
      id
      number
      storeId
      vendor {
        id
        name
      }
      orderLines {
        price
        brand {
          name
          id
        }
        item {
          name
          size
          strain
          category {
            id
            masterCategory {
              id
              name
            }
          }
        }
      }
      ...Order
    }
  }
  ${Order}
`;
