import React from "react";
import { Autocomplete, CircularProgress, TextField } from "@mui/material";
import { Controller, useFormContext } from "react-hook-form";

function LinesAutoComplete({
  rules,
  index,
  label,
  options,
  loading,
  disabled = false,
  required = false,
  inputProps = {},
}) {
  const {
    formState: { errors },
  } = useFormContext();

  return (
    <Controller
      name={`lines.${index}.${label?.toLowerCase()}`}
      render={({ field: { onChange, onBlur, value, ref } }) => (
        <Autocomplete
          disablePortal
          getOptionLabel={(option) => option?.name || label.toLowerCase()}
          options={options}
          disabled={disabled}
          loading={loading}
          isOptionEqualToValue={(option, value) => option.id === value.id}
          onChange={(_, data) => {
            onChange(data?.id);
          }}
          renderInput={(params) => (
            <TextField
              required={required}
              {...params}
              label={label}
              error={Boolean(
                errors?.lines?.[index]?.[label.toLowerCase()]?.message ?? false
              )}
              helperText={
                errors?.lines?.[index]?.[label.toLowerCase()]?.message ?? ""
              }
              // InputLabelProps={{
              //   shrink: false,
              // }}
              InputProps={{
                ...params.InputProps,
                ...inputProps,
                endAdornment: (
                  <React.Fragment>
                    {loading ? (
                      <CircularProgress size={20} />
                    ) : (
                      params.InputProps.endAdornment
                    )}
                  </React.Fragment>
                ),
              }}
            />
          )}
        />
      )}
      rules={rules}
    />
  );
}

export default LinesAutoComplete;
