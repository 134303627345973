import { useAuth } from "../context/AuthContext";
import { useEffect } from "react";
import FullPageSpinner from "../components/common/FullPage";
import { useNavigate } from "react-router-dom";
import LoginPage from "../pages/login";
import useAuthStore from "../store/authStore";

function PrivateRoute({ children, allowedRoles }) {
  const navigate = useNavigate();
  const { isAuthenticated } = useAuthStore();

  // useEffect(() => {
  //   if (!isAuthenticated) {
  //     navigate("/", { replace: true });
  //   }
  // }, []);
  console.log(isAuthenticated);

  if (!isAuthenticated) {
    return <LoginPage />;
  }

  // if (user === null || !user) {
  //   return <LoginPage />;
  // }

  return isAuthenticated && children;
}

export default PrivateRoute;
