import React from "react";
import { Typography } from "@mui/material";

import DetailModal from "../../common/Lists/DetailModal";

function BrandDetail({ handleClose, defaultValues }) {
  return (
    <DetailModal title={"Brand Detail"} handleClose={handleClose}>
      <Typography variant="label">Name: {defaultValues.data.name}</Typography>
      <Typography variant="label">Vendor: {defaultValues.data.vendor?.name}</Typography>
    </DetailModal>
  );
}

export default BrandDetail;
