import { createBrowserRouter, RouterProvider } from "react-router-dom";
import LoginPage from "../pages/login";
import RegisterPage from "../pages/register";
import Root from "./Root";
import ErrorPage from "./ErrorPage";
import Orders from "../pages/orders";
import Create from "../components/orders/create/CreateOrder";
import { useAuth } from "../context/AuthContext";
import Dashboard from "../pages/Dashboard";
import Vendors from "../pages/Vendors";
import Items from "../pages/Items";
import Categories from "../pages/Categories";
import Brands from "../pages/Brands";
import PrivateRoute from "./PrivateRoute";
import Settings from "../pages/Settings";

const Routes = () => {
  const { user } = useAuth();

  // const routesForPublic = [
  //   {
  //     path: "/",
  //     element: <LoginPage />,
  //     errorElement: <ErrorPage />,
  //   },
  //   {
  //     path: "register",
  //     element: <RegisterPage />,
  //     errorElement: <ErrorPage />,
  //   },
  // ];

  // Define routes accessible only to authenticated users
  const routesForAuthenticatedOnly = [
    {
      path: "/",
      element: (
        <PrivateRoute allowedRoles={["admin", "viewer"]}>
          <Root />
        </PrivateRoute>
      ),
      errorElement: <ErrorPage />,
      children: [
        {
          path: "",
          element: <Dashboard />,
        },
        {
          path: "orders",
          element: <Orders />,
        },
        {
          path: "order/create",
          element: <Create />,
        },
        {
          path: "vendors",
          element: <Vendors />,
        },
        {
          path: "items",
          element: <Items />,
        },
        {
          path: "categories",
          element: <Categories />,
        },
        {
          path: "brands",
          element: <Brands />,
        },
        {
          path: "settings",
          element: <Settings />,
        },
      ],
    },
  ];

  // Define routes accessible only to non-authenticated users
  // const routesForNotAuthenticatedOnly = [
  //   {
  //     path: "/",
  //     element: <LoginPage />,
  //     errorElement: <ErrorPage />,
  //   },
  // ];

  // Combine and conditionally include routes based on authentication status
  const router = createBrowserRouter([...routesForAuthenticatedOnly]);

  // Provide the router configuration using RouterProvider
  return <RouterProvider router={router} />;
};

export default Routes;
