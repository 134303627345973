import React from "react";
import { Box, CircularProgress, Tooltip, SvgIcon } from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import IconButton from "@mui/material/IconButton";
import EnhancedTable from "../../common/Table/EnhancedTable";
import PagePlaceholder from "../../common/PagePlaceholder.jsx/PagePlaceholder";
import StatusChip from "../../common/StatusChip";
import tableCss from "../../common/Table/styles.module.css";

const headCells = [
  { id: "number", label: "PO#" },
  { id: "vendor", label: "Vendor" },
  { id: "status", label: "Status" },
  { id: "orderDate", label: "Order Date" },
  { id: "expecteddate", label: "Expected Date" },
  { id: "receiptDate", label: "Receipt Date" },
  { id: "invoiceNumber", label: "Invoice#" },
  { id: "totalAmount", label: "Total Amount" },
  { id: "actions", label: "" },
];

function OrderTable({ handleViewDetail, isLoading, filteredOrders }) {
  if (isLoading)
    return (
      <PagePlaceholder img={<CircularProgress />} text="Loading orders..." />
    );

  const rows = filteredOrders?.map((order) => ({
    cells: {
      number: {
        rawValue: order.number,
        content: order.number,
      },
      vendor: {
        rawValue: order.vendor,
        content: order.vendor.name,
      },
      status: {
        rawValue: order.status,
        content: <StatusChip status={order.status} />,
      },
      orderDate: {
        rawValue: order.orderDate,
        content: new Date(Number(order.orderDate)).toLocaleDateString(),
      },
      expecteddate: {
        rawValue: order?.expecteddate,
        content: order?.expecteddate ? new Date(Number(order?.expecteddate)).toLocaleDateString() : "-",
      },
      receiptDate: {
        rawValue: order?.receiveddate,
        content: order?.receiveddate ? new Date(Number(order?.receiveddate)).toLocaleDateString(): "-",
      },
      invoiceNumber: {
        rawValue: order?.invoiceNumber,
        content: order?.invoiceNumber,
      },
      totalAmount: {
        rawValue: order.totalAmount,
        content: order.totalAmount,
      },
      actions: {
        rawValue: "Actions",
        content: (
          <div className={tableCss.actions}>
            <Tooltip title="View Details" placement="top">
              <IconButton
                onClick={() => {
                  handleViewDetail(order);
                }}
                size="small"
              >
                <SvgIcon
                  component={MoreVertIcon}
                  inheritViewBox
                  sx={{ color: "primary.dark" }}
                  fontSize="small"
                />
              </IconButton>
            </Tooltip>
          </div>
        ),
      },
    },
  }));

  return (
    <main>
      {filteredOrders?.length > 0 ? (
        <EnhancedTable rows={rows} headCells={headCells} mobileVariant />
      ) : (
        <Box bgcolor="background.paper" borderRadius={1}>
          <PagePlaceholder text="No entries found" />
        </Box>
      )}
    </main>
  );
}

export default OrderTable;
