import React from "react";
import { Controller, useFormContext } from "react-hook-form";
import { TextField as MUITextField } from "@mui/material";

function TextField({
  name,
  label,
  required = false,
  type = "text",
  rules = {},
}) {
  const { control, setValue } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <MUITextField
          {...field}
          type={type}
          error={!!(field.error && field.touched)}
          helperText={field.error && field.touched ? field.error.message : ""}
          value={field.value}
          onChange={(e) => {
            // console.log(data);
            field.onChange(e.target.value);
          }}
          label={label}
          variant="outlined"
          fullWidth
          required={required}
        />
      )}
      rules={rules}
    />
  );
}

export default TextField;
