import { Outlet } from "react-router-dom";
import * as React from "react";
import Box from "@mui/material/Box";
import Navigation from "../components/common/Navigation/index";

export default function Root() {
  return (
    <>
      <Navigation>
        <Box
          sx={{
            p: 2,
            minHeight: "100vh",
          }}
        >
          <Outlet />
        </Box>
      </Navigation>
    </>
  );
}
