import React from "react";

import List from "../components/orders";
function orders() {
  return (
      <List />
  );
}

export default orders;
