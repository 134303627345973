import { gql } from "@apollo/client";

export const OrderLine = gql`
  fragment OrderLine on OrderLine {
    quantity
    price
    itemId
    brandId
  }
`;
