export const formatDate = (timeStamp) => {
  const date = new Date(Number(timeStamp));

  if (isNaN(date.getTime())) return timeStamp;

  return date.toLocaleDateString("en-US", {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
  });
};
