import { gql } from "@apollo/client";
import { OrderLine } from "../orderLine/fragment";

export const Order = gql`
  fragment Order on Order {
    orderDate
    totalAmount
    status
    notes
    vendorId
    orderLines {
      ...OrderLine
    }
  }
  ${OrderLine}
`;
