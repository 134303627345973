import { useQuery } from "@apollo/client";
import { GET_ITEMS } from "../../../graphql/items/query";
import { useEffect, useState } from "react";

export const useItems = (brandId, name = "") => {
  const [items, setItems] = useState([]);
  const { loading, error, data } = useQuery(GET_ITEMS, {
    variables: {
      filter: {
        name,
        brandId: brandId || null,
      },
    },
    onCompleted: (data) => {
      if (data?.items) {
        setItems(data.items);
      }
    },
  });

  useEffect(() => {
    if (data?.items) {
      setItems(data.items);
    }
  }, [data]);

  const getItem = (id) => {
    return items.find((brand) => brand.id === id);
  };

  return {
    items,
    loading,
    error,
    getItem,
  };
};
