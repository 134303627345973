import { gql } from "@apollo/client";
import { Order } from "./fragment";
export const CREATE_ORDER = gql`
  mutation createOrder($input: CreateOrderInput!) {
    createOrder(input: $input) {
      ...Order
    }
  }
  ${Order}
`;
