import React, { useState, useEffect } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { Autocomplete as MUIAutoComplete, TextField } from "@mui/material";
import Loader from "../common/Loader.jsx";

function AutoComplete({ name, label, data, loading }) {
  const [options, setOptions] = useState([]);
  const {
    control,
    formState: { errors },
  } = useFormContext();

  useEffect(() => {
    if (!loading && data) {
      setOptions(data);
    }
  }, [data, loading]);
  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <MUIAutoComplete
          disablePortal
          getOptionLabel={(option) => option?.name}
          options={!loading ? options : []}
          onChange={(_, data) => field.onChange(data?.id)}
          renderInput={(params) => (
            <TextField
              {...params}
              label={label}
              error={Boolean(errors.label)}
              helperText={errors.label?.message}
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <React.Fragment>
                    {loading ? <Loader color="inherit" size={20} /> : null}
                    {params.InputProps.endAdornment}
                  </React.Fragment>
                ),
              }}
            />
          )}
        />
      )}
      rules={{
        required: {
          value: true,
          message: "*",
        },
      }}
    />
  );
}

export default AutoComplete;
