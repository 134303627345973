import { useMutation } from "@apollo/client";
import { CREATE_ITEM } from "../../../graphql/items/mutation";
import { GET_ITEMS } from "../../../graphql/items/query";
import { useNotification } from "../../../services/hooks/useNotification";
import useAuthStore from "../../../store/authStore";

export const useCreateItem = (handleClose) => {
  const { currentStore } = useAuthStore();
  const { notify } = useNotification();
  const [createItem, { loading, error }] = useMutation(CREATE_ITEM, {
    onCompleted: () => {
      handleClose();
      notify("Item added successfully", "success");
    },
    onError: (error) => {
      notify("Failed to add item", "error");
    },
    refetchQueries: [
      GET_ITEMS, // DocumentNode object parsed with gql
      "getItems", // Query name
    ],
  });

  const addItem = async (input) => {
    const item = {
      name: input.name,
      description: input.description,
      size: input.size,
      strain: input.strain,
      brandId: input.brand,
      category: input.category,
      storeId: currentStore?.id,
    };
    return await createItem({
      variables: {
        input: {
          ...item,
        },
      },
    });
  };

  return {
    addItem,
    loading,
    error,
  };
};
