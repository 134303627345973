export const ModalType = {
  ENTRY: "entry",
  REMOVE: "remove",
  DETAILS: "details",
  EDIT: "edit",
  EXPORT: "export",
};

export const defaultOpen = {
  [ModalType.ENTRY]: false,
  [ModalType.REMOVE]: false,
  [ModalType.DETAILS]: false,
  [ModalType.EDIT]: false,
  [ModalType.EXPORT]: false,
};
