import { useCSVDownloader } from "react-papaparse";
import { useMemo } from "react";

import ModalDialog from "../../common/ModalDialog";
import {
  DialogContent,
  DialogActions,
  Typography,
  Button,
} from "@mui/material";

export const _getCsvData = (data, columns) => {
  const csvData = data.map((dataItem) => {
    return Object.keys(dataItem)
      .filter((key) => columns.includes(key))
        .map((key) => {
          if(typeof dataItem[key] === 'object') {
            return dataItem[key].name;
          }
          return dataItem[key];
      });
  });

  csvData.unshift(columns.map((column) => column.toUpperCase()));
  return csvData;
};

function ExportDialog({
  defaultValues,
  handleClose,
  coloumnsForCsvExport,
  name,
}) {
  console.log(defaultValues);
  const length = defaultValues?.length;
  const { CSVDownloader } = useCSVDownloader();
  const filename = `${name}-${new Date().toISOString().slice(0, 10)}`;

  const csvData = useMemo(
    () => _getCsvData(defaultValues, coloumnsForCsvExport),
    [coloumnsForCsvExport, defaultValues]
  );

  const onSubmit = (e) => {
    e.preventDefault();

    setTimeout(() => {
      handleClose();
    }, 300);
  };

  return (
    <ModalDialog open onClose={handleClose} dialogTitle={`Export ${name}`}>
      <DialogContent sx={{ p: "24px !important" }}>
        <Typography data-testid="export-summary">
          You&apos;re about to export a CSV file with{" "}
          <b>
            {length} {name} {length === 1 ? "entry" : "entries"}
          </b>
          .
        </Typography>
      </DialogContent>

      <DialogActions>
        <Button variant="outlined" onClick={handleClose}>
          Cancel
        </Button>
        <CSVDownloader
          filename={filename}
          bom
          config={{ delimiter: "," }}
          data={csvData}
          style={{ order: 2 }}
        >
          <Button
            data-testid="export-modal-btn"
            variant="contained"
            disableElevation
            onClick={onSubmit}
          >
            Export
          </Button>
        </CSVDownloader>
      </DialogActions>
    </ModalDialog>
  );
}

export default ExportDialog;
