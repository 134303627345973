import { Typography } from "@mui/material";
import css from "./styles.module.css";

const PagePlaceholder = ({ img, text, children }) => {
  return (
    <div className={css.container}>
      {img}

      {typeof text === "string" ? (
        <Typography variant="body1" mt={2}>
          {text}
        </Typography>
      ) : (
        text
      )}

      {children}
    </div>
  );
};

export default PagePlaceholder;
