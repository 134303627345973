import { create } from "zustand";
import { persist } from "zustand/middleware";

const useAuthStore = create(
  persist(
    (set) => ({
      user: null,
      token: null,
      permissions: [],
      companyId: null,
      currentStore: null,
      isAuthenticated: false,
      stores: [],
      setStores: (stores) => set({ stores }),
      setCurrentStore: (currentStore) => set({ currentStore }),

      login: (loginResponse) =>
        set({
          user: loginResponse?.user,
          token: loginResponse?.token,
          permissions: loginResponse?.permissions,
          currentStore: loginResponse?.permissions.find(
            (permission) => permission?.accessLevel?.toLowerCase() === "admin"
          )?.store,
          companyId: loginResponse?.user?.companyId,
          isAuthenticated: true,
        }),
      logout: () =>
        set({
          user: null,
          token: null,
          permissions: [],
          companyId: null,
          currentStore: null,
          isAuthenticated: false,
          stores: [],
        }),
    }),
    {
      name: "auth-storage", // unique name for the storage
      getStorage: () => localStorage, // (optional) by default, 'localStorage' is used
    }
  )
);

export default useAuthStore;
