import { gql } from "@apollo/client";

export const GET_COMPANY = gql`
  query getCompany($companyId: ID!) {
    company(id: $companyId) {
      id
      name
      address
      stores {
        id
        name
        address
      }
      createdAt
      updatedAt
    }
  }
`;
