import React from "react";
import { FormProvider, useForm, Controller } from "react-hook-form";
import {
  TextField,
  Button,
  Box,
  ButtonGroup,
  Stack,
} from "@mui/material";
import { CREATE_USER } from "../graphql/users/mutation";
import { useMutation } from "@apollo/client";
import { useNotification } from "../services/hooks/useNotification";
import { Link, useNavigate } from "react-router-dom";

function RegisterPage() {
  const {
    handleSubmit,
    methods,
    control,
    formState: { errors },
  } = useForm();
  const navigate = useNavigate();
  const [register, { data }] = useMutation(CREATE_USER);
  const { notify } = useNotification();

  const onSubmit = async (data) => {
    const { username, email, password, firstName, lastName } = data;
    try {
      await register({
        variables: { username, email, password, firstName, lastName },
      });

      navigate("/");

      notify("Successfully registered!", "success");
    } catch (error) {
      notify(error?.message || "Something went wrong!", "error");
    }
  };

  return (
    <FormProvider {...methods}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
          height: "90vh",
        }}
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <Stack spacing={3}>
            <Controller
              name="firstName"
              control={control}
              rules={{ required: "First Name is required*" }}
              render={({ field }) => (
                <TextField
                  {...field}
                  error={Boolean(errors.firstName)}
                  helperText={errors.firstName?.message}
                  label="First Name"
                />
              )}
            />

            <Controller
              name="lastName"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  error={Boolean(errors.lastName)}
                  helperText={errors.lastName?.message}
                  label="Last Name"
                />
              )}
              rules={{ required: "Last Name is required*" }}
            />

            <Controller
              name="username"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  error={Boolean(errors.username && errors.username.message)}
                  helperText={errors.username?.message}
                  label="Username"
                />
              )}
              rules={{ required: "Username is required*" }}
            />
            <Controller
              name="email"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  error={Boolean(errors.email?.message)}
                  helperText={errors.email?.message}
                  label="Email"
                />
              )}
              rules={{ required: "Email is required*" }}
            />
            <Controller
              name="password"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Password"
                  type="password"
                  sx={{ width: "300px" }}
                  error={Boolean(errors.password && errors.password?.message)}
                  helperText={errors.password?.message}
                />
              )}
              rules={{
                required: "Password is required*",
                validate: (value) =>
                  value.length > 5 || "Password must be 6+ characters",
              }}
            />
            <ButtonGroup>
              <Button type="submit" variant="contained">
                Register
              </Button>
              <Button>
                <Link to="/">Login</Link>
              </Button>
            </ButtonGroup>
          </Stack>
        </form>
      </Box>
    </FormProvider>
  );
}

export default RegisterPage;
