import React, { useEffect, useState } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { Autocomplete, TextField } from "@mui/material";
import { useVendors } from "./hooks/useVendors";
import Loader from "../common/Loader";

export function VendorAutocomplete() {
  const {
    control,
    formState: { errors },
  } = useFormContext();
  const { vendors, loading } = useVendors();
  const [options, setOptions] = useState([]);

  useEffect(() => {
    if (vendors && vendors.length > 0) {
      setOptions(vendors);
    }
  }, [vendors]);

  return (
    <Controller
      name="vendor"
      control={control}
      render={({ field }) => (
        <Autocomplete
          disablePortal
          getOptionLabel={(option) => option.name}
          options={!loading ? options : []}
          onChange={(_, data) => field.onChange(data?.id)}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Vendor"
              error={Boolean(errors.vendor)}
              helperText={errors.vendor?.message}
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <React.Fragment>
                    {loading ? <Loader color="inherit" size={20} /> : null}
                    {params.InputProps.endAdornment}
                  </React.Fragment>
                ),
              }}
            />
          )}
        />
      )}
      rules={{
        required: {
          value: true,
          message: "Vendor is required*",
        },
      }}
    />
  );
}

export default VendorAutocomplete;
