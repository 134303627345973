import { gql } from "@apollo/client";

export const LOGIN = gql`
  mutation login($username: String!, $password: String!) {
    login(username: $username, password: $password) {
      token
      user {
        storeId
        companyId
      }
      permissions {
        storeId
        accessLevel
        store {
          name
          id
        }
      }
    }
  }
`;

export const CREATE_USER = gql`
  mutation createUser(
    $username: String!
    $password: String!
    $firstName: String
    $lastName: String
    $email: String!
    $storeId: ID!
    $companyId: ID!
  ) {
    createUser(
      input: {
        username: $username
        password: $password
        firstName: $firstName
        lastName: $lastName
        email: $email
        storeId: $storeId
        companyId: $companyId
      }
    ) {
      username
      firstName
    }
  }
`;
