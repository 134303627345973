import { useMutation } from "@apollo/client";
import { CREATE_VENDOR } from "../../../graphql/vendors/mutation";
import { GET_VENDORS } from "../../../graphql/vendors/query";
import { useNotification } from "../../../services/hooks/useNotification";
import useAuthStore from "../../../store/authStore";
export const useMutateVendor = (handleClose) => {
  const { currentStore } = useAuthStore();

  const { notify } = useNotification();
  const [createVendor, { loading, error }] = useMutation(CREATE_VENDOR, {
    onCompleted: () => {
      notify("Vendor added successfully", "success");
      handleClose();
    },
    onError: (error) => {
      notify("Failed to add vendor", "error");
    },
    update(cache, { data: { createVendor } }) {
      const { vendors } = cache.readQuery({
        query: GET_VENDORS,
        variables: {
          filter: {
            name: "",
          },
          sort: {
            field: "name",
            order: "ASC",
          },
        },
      });

      cache.writeQuery({
        query: GET_VENDORS,
        data: {
          vendors: [...vendors, createVendor],
        },
        variables: {
          filter: {
            name: "",
          },
          sort: {
            field: "name",
            order: "ASC",
          },
        },
      });
    },
  });

  const addVendor = async (input) => {
    input.storeId = currentStore?.id;
    const { data } = await createVendor({
      variables: {
        input,
      },
    });

    return data.createVendor;
  };

  return {
    addVendor,
    loading,
  };
};
