import React, { createContext, useState, useEffect, useMemo } from "react";
import {
  ApolloProvider as ClientProvider,
  ApolloClient,
  InMemoryCache,
  ApolloLink,
  HttpLink,
  Observable,
} from "@apollo/client";
import axios from "axios";
import { onError } from "@apollo/client/link/error";
import { setContext } from "@apollo/client/link/context";
import { jwtDecode } from "jwt-decode";
import graphqlObservable from "../apollo/graphqlObservable";
import { useNotification } from "../services/hooks/useNotification";

const ApolloContext = createContext();

export const useApollo = () => {
  const context = React.useContext(ApolloContext);
  if (!context) {
    throw new Error("useApollo must be used within an ApolloProvider");
  }
  return context;
};

const query = `
    query RefreshToken {
      refreshToken {
        accessToken
      }
    }
  `;

const payload = {
  query,
  operationName: "RefreshToken", // Ensure this matches the operation name in the query
};

export const ApolloProvider = ({ children }) => {
  const { notify } = useNotification();
  const [client, setClient] = useState(null);
  const [isTokenExpired, setIsTokenExpired] = useState(false);
  let accessToken = localStorage.getItem("authToken") || null;

  const refreshAccessToken = async () => {
    console.log("Running refreshAccessToken");
    const header = accessToken ? `Bearer ${accessToken}` : "";
    return axios.post(
      `/api`,
      { ...payload },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: header,
        },
        withCredentials: true,
      }
    );
  };

  const errorLink = onError(({ graphQLErrors, operation, forward }) => {
    if (graphQLErrors) {
      for (let err of graphQLErrors) {
        if (
          err.extensions?.code === "ACCESS_TOKEN_NOT_PROVIDED" ||
          err.extensions?.code === "UNAUTHENTICATED" ||
          err.extensions?.code === "EXPIRED_ACCESS_TOKEN"
        ) {
          return graphqlObservable(refreshAccessToken()).flatMap((value) => {
            localStorage.setItem(
              "authToken",
              value?.data.data.refreshToken.accessToken
            );
            operation.setContext(({ headers }) => ({
              headers: {
                ...headers,
                Authorization: `Bearer ${value?.data.data.refreshToken.accessToken}`,
              },
            }));
            return forward(operation);
          });
        }

        if (err.extensions?.code === "TOKENS_EXPIRED") {
          notify("Your session has expired. Please log in again.", "error");
          setIsTokenExpired(true);
          if (window.location.pathname !== "/") {
            window.location.pathname = "/";
            localStorage.clear();
          }
        }
      }
    }
  });

  const authLink = setContext(async (_, { headers }) => {
    const accessToken = localStorage.getItem("authToken");
    return {
      headers: {
        ...headers,
        authorization: accessToken ? `Bearer ${accessToken}` : "",
      },
    };
  });

  const isTokenValidOrUndefined = async () => {
    if (!accessToken) return true;
    try {
      const { exp } = jwtDecode(accessToken);
      return Date.now() < exp * 10000;
    } catch {
      return false;
    }
  };

  class TokenRefreshLink extends ApolloLink {
    request(operation, forward) {
      return new Observable((observer) => {
        // First, check if the token is valid or not
        isTokenValidOrUndefined()
          .then((isValid) => {
            if (isValid) {
              // If the token is valid, forward the operation
              forward(operation).subscribe({
                next: observer.next.bind(observer),
                error: observer.error.bind(observer),
                complete: observer.complete.bind(observer),
              });
            } else {
              // If the token is invalid, refresh it
              refreshAccessToken()
                .then(({ data }) => {
                  // After refreshing the token, forward the operation
                  localStorage.setItem(
                    "authToken",
                    data.data.refreshToken.accessToken
                  );
                  forward(operation).subscribe({
                    next: observer.next.bind(observer),
                    error: observer.error.bind(observer),
                    complete: observer.complete.bind(observer),
                  });
                })
                .catch((err) => {
                  // If there is an error refreshing the token, propagate the error
                  observer.error(err);
                });
            }
          })
          .catch((err) => {
            // If there is an error validating the token, propagate the error
            observer.error(err);
          });
      });
    }
  }

  const link = ApolloLink.from([
    new TokenRefreshLink(),
    errorLink,
    authLink,
    new HttpLink({
      uri: `/api`,
      credentials: "include",
    }),
  ]);

  const clientInstance = new ApolloClient({
    link,
    cache: new InMemoryCache(),
  });

  useEffect(() => {
    setClient(clientInstance);
  }, []);

  const value = useMemo(
    () => ({
      client,
      isTokenExpired,
    }),
    [client, isTokenExpired]
  );

  return (
    <ApolloContext.Provider value={value}>
      {client && <ClientProvider client={client}>{children}</ClientProvider>}
    </ApolloContext.Provider>
  );
};
