import React, { useState, useMemo } from "react";
import { useGetOrders } from "./hooks/useGetOrders";
import OrderHeader from "./list/OrderHeader";
import OrderDetailsDialog from "./list/OrderDetailsDialog";
import OrderTable from "./list/OrderTable";
import useAuthStore from "../../store/authStore";

const ModalType = {
  ENTRY: "entry",
  REMOVE: "remove",
  DETAILS: "details",
  EDIT: "edit",
  EXPORT: "export",
};

const defaultOpen = {
  [ModalType.ENTRY]: false,
  [ModalType.REMOVE]: false,
  [ModalType.DETAILS]: false,
  [ModalType.EDIT]: false,
  [ModalType.EXPORT]: false,
};

function Orders() {
  const { currentStore } = useAuthStore();
  const [searchQuery, setSearchQuery] = useState("");
  const { orders, loading } = useGetOrders(currentStore?.id);
  const [defaultValues, setDefaultValues] = useState(undefined);
  const [open, setOpen] = useState(defaultOpen);

  const filteredEntries = useMemo(() => {
    if (!searchQuery) {
      return orders;
    }

    const query = searchQuery.toLowerCase();
    return orders.filter((order) => {
      const {
        number,
        vendor: { name },
      } = order;

      return (
        number.toString().includes(query) ||
        // id.toLowerCase().includes(query) ||
        name.toLowerCase().includes(query)
      );
    });
  }, [orders, searchQuery]);

  const handleClose = () => {
    setOpen(defaultOpen);
    setDefaultValues(undefined);
  };
  const handleOpenModal = (type) => () => {
    setOpen((prev) => ({ ...prev, [type]: true }));
  };
  const handleOpenModalWithValues = (modal, data) => {
    setDefaultValues(data);
    handleOpenModal(modal)();
  };

  return (
    <>
      <OrderHeader
        handleOpenModal={() => {}}
        handleOpenModalWithValues={() => {}}
        allOrders={orders || []}
        searchQuery={searchQuery}
        onSearchQueryChange={setSearchQuery}
      />
      <OrderTable
        handleViewDetail={(order) =>
          handleOpenModalWithValues(ModalType.DETAILS, order)
        }
        isLoading={loading}
        filteredOrders={filteredEntries}
      />

      {open[ModalType.DETAILS] && (
        <OrderDetailsDialog
          handleClose={handleClose}
          defaultValues={defaultValues}
        />
      )}
    </>
  );
}

export default Orders;
