import {
  Button,
  CircularProgress,
  DialogActions,
  DialogContent,
} from "@mui/material";
import { FormProvider, useForm } from "react-hook-form";
import SendIcon from "@mui/icons-material/Send";
import ModalDialog from "../../common/ModalDialog/index";
import TextField from "../../common/TextField";
import { useMutateMasterCategory } from "./hooks/useMutateMasterCategory";

function CreateMasterCategory({ handleClose, defaultValues }) {
  const { addMasterCategory, loading: submitting } =
    useMutateMasterCategory(handleClose);
  const methods = useForm({
    defaultValues,
    mode: "onChange",
  });

  const { handleSubmit, formState } = methods;

  const submitCallback = handleSubmit(async (data) => {
    try {
      await addMasterCategory(data);
    } catch (e) {
      console.log(e);
    }
  });

  const onSubmit = (e) => {
    e.stopPropagation();
    submitCallback(e);
  };

  return (
    <ModalDialog
      open
      onClose={handleClose}
      dialogTitle="Create Master Category"
    >
      <FormProvider {...methods}>
        <form onSubmit={onSubmit}>
          <DialogContent
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 2,
            }}
          >
            <TextField label="Name" name="name" required />
          </DialogContent>

          <DialogActions
            sx={{
              display: "flex",
              margin: 1,
            }}
          >
            <Button
              variant="contained"
              data-testid="cancel-btn"
              onClick={handleClose}
            >
              Cancel
            </Button>
            <Button
              data-testid="save-btn"
              type="submit"
              variant="contained"
              disabled={!formState.isValid}
              endIcon={
                formState.isSubmitting || submitting ? (
                  <CircularProgress size={20} />
                ) : (
                  <SendIcon />
                )
              }
              disableElevation
            >
              Submit
            </Button>
          </DialogActions>
        </form>
      </FormProvider>
    </ModalDialog>
  );
}

export default CreateMasterCategory;
