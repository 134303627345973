import React, { useState, useRef } from "react";
import {
  Drawer,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Collapse,
} from "@mui/material";
import { ExpandMore, ExpandLess } from "@mui/icons-material";
import { useNavigate, useLocation } from "react-router-dom";

const List2 = ({ menuItems }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [openList, setOpenList] = useState(null);
  const timerRef = useRef(null);

  const handleMouseEnter = (listName) => {
    clearTimeout(timerRef.current);
    setOpenList(listName);
  };

  const handleMouseLeave = () => {
    timerRef.current = setTimeout(() => {
      setOpenList(null);
    }, 300); // Adjust the delay as needed
  };

  return (
    <Drawer variant="permanent">
      <List>
        {menuItems.map((item, index) => (
          <React.Fragment key={item.text}>
            <ListItemButton
              onMouseEnter={() => handleMouseEnter(item.text)}
              onMouseLeave={handleMouseLeave}
              onClick={() => item?.to && navigate(item?.to)}
              selected={location.pathname === item.to}
              sx={{
                display: "flex",
                color: (theme) => theme.palette.text.secondary,
                borderLeft: (theme) =>
                  `4px solid ${theme.palette.primary.main}`,
                "& svg": {
                  color: (theme) => theme.palette.text.secondary,
                },
                "&:hover": {
                  backgroundColor: (theme) => theme.palette.action.hover,
                  color: (theme) => theme.palette.primary.contrastText,
                  "& svg": {
                    color: (theme) => theme.palette.primary.contrastText,
                  },
                },
                ...(location.pathname?.toLowerCase() ===
                  item?.to?.toLowerCase() && {
                  color: (theme) => theme.palette.primary.main,
                  borderRadius: "5px",
                  backgroundColor: (theme) => theme.palette.action.hover,
                }),
              }}
            >
              <ListItemIcon>{item.icon}</ListItemIcon>
              <ListItemText primary={item.text} />
              {item.subItems &&
                (openList === item.text ? <ExpandLess /> : <ExpandMore />)}
            </ListItemButton>

            {item.subItems && (
              <Collapse
                in={openList === item.text}
                timeout="auto"
                unmountOnExit
                onMouseEnter={() => handleMouseEnter(item.text)}
                onMouseLeave={handleMouseLeave}
              >
                <List
                  component="div"
                  disablePadding
                  style={{ marginLeft: "20px" }}
                >
                  {item.subItems.map((subItem) => (
                    <ListItemButton
                      onClick={() => navigate(subItem.to)}
                      key={subItem.text}
                      button
                      selected={location.pathname === subItem.to}
                      sx={{
                        color: (theme) => theme.palette.primary.main,
                        "&:hover": {
                          backgroundColor: (theme) =>
                            theme.palette.action.hover,
                          color: (theme) => theme.palette.primary.contrastText,
                        },
                      }}
                    >
                      <ListItemText primary={subItem.text} />
                    </ListItemButton>
                  ))}
                </List>
              </Collapse>
            )}
          </React.Fragment>
        ))}
      </List>
    </Drawer>
  );
};

export default List2;
