import { useFormContext } from "react-hook-form";
import React, { useEffect } from "react";
import BrandsAutocomplete from "../../brand/BrandsLinesAutocomplete";
import ItemAutocomplete from "../../item/ItemAutocomplete";
import NumberField from "../../common/NumberField";
import DeleteForeverRoundedIcon from "@mui/icons-material/DeleteForeverRounded";

import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";

const lineheaders = ["", "Brand", "Item", "Units", "Price", "Total Price", ""]; // Add more subjects as needed
const Lines = React.forwardRef(({ index, onRemove }, ref) => {
  const {
    formState: { errors, dirtyFields },
    watch,
    setValue,
  } = useFormContext();

  const price = watch(`lines.${index}.price`);
  const units = watch(`lines.${index}.units`);

  useEffect(() => {
    const total = parseFloat(price || 0) * parseFloat(units || 0);
    setValue(`lines.${index}.total`, total.toFixed(2));
  }, [price, units, index, setValue]);
  return (
    <Table sx={{ padding: "10px" }}>
      <TableHead>
        {index === 0 && (
          <TableRow>
            {lineheaders.map((subject, index) => (
              <TableCell key={index}>{subject}</TableCell>
            ))}
          </TableRow>
        )}
      </TableHead>
      <TableBody>
        <TableRow key={index}>
          <TableCell>{index + 1}</TableCell>
          <TableCell width={"30%"}>
            <BrandsAutocomplete index={index} />
          </TableCell>

          <TableCell width={"30%"}>
            <ItemAutocomplete index={index} />
          </TableCell>
          <TableCell>
            <NumberField
              name={`lines.${index}.units`}
              label="Units"
              rules={{
                required: {
                  value: true,
                  message: "Units is required",
                },
                min: {
                  value: 0,
                  message: "Units cannot be negative",
                },
              }}
            />
          </TableCell>
          <TableCell>
            <NumberField
              name={`lines.${index}.price`}
              label="Price"
              required
              rules={{
                required: {
                  value: true,
                  message: "Price is required",
                },
                min: {
                  value: 0,
                  message: "Price cannot be negative",
                },
              }}
            />
          </TableCell>

          <TableCell>
            {/* {index === 0 && <Typography variant="body1">EXT. Wholesale</Typography>} */}
            <NumberField
              name={`lines.${index}.total`}
              label="Total"
              required
              rules={{
                min: {
                  value: 0,
                  message: "Total must be greater than 0",
                },
                validate: (value) => {
                  // Ensure price and units are defined and have valid numeric values
                  if (
                    typeof price === "undefined" ||
                    typeof units === "undefined"
                  ) {
                    return "Price or units are not defined";
                  }

                  const parsedValue = parseFloat(value);
                  const parsedPrice = parseFloat(price);
                  const parsedUnits = parseFloat(units);

                  // Calculate the expected total
                  const expectedTotal = parsedPrice * parsedUnits;

                  // Use a tolerance level for floating-point comparison
                  const tolerance = 0.001;

                  if (
                    Math.abs(
                      parsedValue.toFixed(2) - expectedTotal.toFixed(2)
                    ) > tolerance
                  ) {
                    return "Incorrect Total";
                  }
                },
              }}
            />
          </TableCell>
          <TableCell>
            <DeleteForeverRoundedIcon
              color="error"
              onClick={() => onRemove(index)}
              sx={{
                cursor: "pointer",
                "&:hover": {
                  opacity: 0.7,
                },
              }}
            />
          </TableCell>
        </TableRow>
      </TableBody>
    </Table>
  );
});

export default Lines;
