import { useLazyQuery } from "@apollo/client";
import { GET_ORDERS } from "../graphql/order/query";

import React, { createContext, useEffect, useCallback } from "react";
import { GET_COMPANY } from "../graphql/queries/company";
import { useNotification } from "../services/hooks/useNotification";
import useAuthStore from "../store/authStore";
import { useApollo } from "./Apollo";
const AuthContext = createContext();

export const useAuth = () => {
  const context = React.useContext(AuthContext);
  if (!context) {
    throw new Error("useAuth must be used within an AuthProvider");
  }
  return context;
};

export const AuthProvider = ({ children }) => {
  const { client } = useApollo();
  const { notify } = useNotification();
  const [getCompanyInfo, { loading }] = useLazyQuery(GET_COMPANY);
  const {
    login: loginAuthStore,
    logout: handleLogout,
    companyId,
    setStores,
    setCurrentStore,
    stores,
    isAuthenticated,
  } = useAuthStore();

  const handleLogin = useCallback(
    async (loginResponse) => {
      try {
        if (loginResponse) {
          loginAuthStore(loginResponse);
          localStorage.setItem("authToken", loginResponse?.token);
          notify("Login successful", "success");
        } else {
          handleLogout();
        }
      } catch (error) {
        notify(error?.message, "error");
        handleLogout();
      }
    },
    [loginAuthStore, handleLogout, notify]
  );

  useEffect(() => {
    async function fetchCompanyInfo() {
      if (companyId && isAuthenticated) {
        const { data } = await getCompanyInfo({ variables: { companyId } });
        console.log(data);
        if (data) {
          setStores(data?.company?.stores);
        }
      }
    }
    fetchCompanyInfo();
  }, [companyId, getCompanyInfo, isAuthenticated, setStores]);

  // useEffect(() => {
  //   if (data) {
  //     setStores(data.company.stores);
  //   }
  // }, [data, setStores]);

  const handleStoreChange = (newStore) => {
    const selectedStore = stores.find((store) => store.id === newStore?.id);
    if (selectedStore) {
      setCurrentStore(selectedStore);
      client.refetchQueries({
        include: [
          {
            query: GET_ORDERS,
            variables: {
              filter: {
                storeId: selectedStore?.id,
              },
            },
          },
        ],
      });
    }
  };
  const contextValue = {
    handleLogin,
    handleLogout,
    loading,
    handleStoreChange,
  };

  return (
    <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>
  );
};