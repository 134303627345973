import {
  Button,
  CircularProgress,
  DialogActions,
  DialogContent,
  Grid,
  TextField as MuiTextField
} from "@mui/material";
import { FormProvider, useForm } from "react-hook-form";
import SendIcon from "@mui/icons-material/Send";
import ModalDialog from "../../common/ModalDialog/index";
import TextField from "../../common/TextField";
import { useCreateItem } from "../hooks/useCreateItem";
import BrandsAutocomplete from "../../brand/BrandsAutocomplete";
import CategoryAutoComplete from "../../categories/CategoryAutoComplete";

function CreateItem({ handleClose, defaultValues }) {
  const { addItem, loading } = useCreateItem(handleClose);
  const methods = useForm({
    defaultValues,
    mode: "onChange",
  });

  const { handleSubmit, formState } = methods;

  const submitCallback = handleSubmit(async (data) => {
    //create vendor here
    try {
      await addItem(data);
    } catch (e) {
      console.log(e);
    }
  });

  const onSubmit = (e) => {
    e.stopPropagation();
    submitCallback(e);
  };

  return (
    <ModalDialog open onClose={handleClose} dialogTitle="Create Item">
      <FormProvider {...methods}>
        <form onSubmit={onSubmit}>
          <DialogContent
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 2,
            }}
          >
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <TextField label="Name" name="name" required />
              </Grid>
              <Grid item xs={12} md={6}>
                <CategoryAutoComplete />
              </Grid>
            </Grid>

            <Grid container spacing={2}>
              <Grid item xs={12} md={4}>
                <BrandsAutocomplete />
              </Grid>
              <Grid item xs={12} md={4}>
                <TextField label="Size" name="size" />{" "}
              </Grid>
              <Grid item xs={12} md={4}>
                <TextField label="Strain" name="strain" />
              </Grid>
            </Grid>
            <MuiTextField
              name="description"
              label={"Description"}
              multiline
              rows={2}
              sx={{ width: "100%" }}
            />
          </DialogContent>

          <DialogActions
            sx={{
              display: "flex",
              margin: 1,
            }}
          >
            <Button
              variant="contained"
              data-testid="cancel-btn"
              onClick={handleClose}
            >
              Cancel
            </Button>
            <Button
              data-testid="save-btn"
              type="submit"
              variant="contained"
              disabled={!formState.isValid}
              endIcon={
                formState.isSubmitting || loading ? (
                  <CircularProgress size={20} />
                ) : (
                  <SendIcon />
                )
              }
              disableElevation
            >
              Submit
            </Button>
          </DialogActions>
        </form>
      </FormProvider>
    </ModalDialog>
  );
}

export default CreateItem;
