import { useState, useMemo } from "react";
import { useVendors } from "../hooks/useVendors";
import Lists from "../../common/Lists";
import VendorDetail from "./VendorDetail";
import CreateVendor from "./CreateVendor";
import FullPageSpinner from "../../common/FullPage";
import ListHeader from "../../common/Lists/ListsHeader";
import { defaultOpen, ModalType } from "../../../utils/contants/Modal/modal";
import ExportDialog from "../../common/Lists/ExportDialog";

const coloumnsForCsvExport = ["name", "address"];

export default function VendorList() {
  const { vendors, loading } = useVendors();
  const [searchQuery, setSearchQuery] = useState("");
  const [defaultValues, setDefaultValues] = useState(undefined);
  const [open, setOpen] = useState(defaultOpen);

  const filteredEntries = useMemo(() => {
    if (!searchQuery) {
      return vendors;
    }

    const query = searchQuery.toLowerCase();
    return vendors.filter((order) => {
      const { name } = order;

      return name.toLowerCase().includes(query);
    });
  }, [vendors, searchQuery]);

  const handleClose = () => {
    setOpen(defaultOpen);
    setDefaultValues(undefined);
  };
  const handleOpenModal = (type) => () => {
    setOpen((prev) => ({ ...prev, [type]: true }));
  };
  const handleOpenModalWithValues = (modal, data) => {
    setDefaultValues(data);
    handleOpenModal(modal)();
  };

  return (
    <>
      <ListHeader
        handleOpenModal={handleOpenModal}
        handleOpenModalWithValues={handleOpenModalWithValues}
        title="Vendors"
        allListItems={vendors}
        searchQuery={searchQuery}
        onSearchQueryChange={setSearchQuery}
      />
      {loading ? (
        <FullPageSpinner />
      ) : (
        <Lists
          handleViewDetail={(data) =>
            handleOpenModalWithValues(ModalType.DETAILS, { data })
          }
          loading={loading}
          list={filteredEntries}
          label="Vendors"
        />
      )}

      {open[ModalType.ENTRY] && (
        <CreateVendor handleClose={handleClose} defaultValues={defaultValues} />
      )}

      {open[ModalType.DETAILS] && (
        <VendorDetail handleClose={handleClose} defaultValues={defaultValues} />
      )}

      {open[ModalType.EXPORT] && (
        <ExportDialog
          handleClose={handleClose}
          defaultValues={defaultValues}
          coloumnsForCsvExport={coloumnsForCsvExport}
          name="Vendors"
        />
      )}
    </>
  );
}
