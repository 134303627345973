import React from "react";
import { FormProvider, useForm, useFieldArray } from "react-hook-form";
import {
  Grid,
  Button,
  CircularProgress,
  TextField as MuiTextField,
  Stack,
  Typography,
} from "@mui/material";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableContainer,
} from "@mui/material";
import Divider from "@mui/material/Divider";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import LineItem from "./LineItem";

import VendorAutocomplete from "../../vendor/VendorAutocomplete";
import { useCreateOrder } from "./hooks/useCreateOrder";
import DateField from "../../common/DateField";
import TextField from "../../common/TextField";

import useAuthStore from "../../../store/authStore.js";

function Create() {
  const { currentStore } = useAuthStore();
  const methods = useForm({
    defaultValues: {
      lines: [{ brand: "", item: "", units: "", price: "", total: "" }],
    },
  });

  const total = methods.watch("lines", []).reduce((total, line) => {
    return total + parseInt(line.units) * parseFloat(line.price);
  }, 0);

  // const subTotal = methods.watch("lines", []).reduce((total, line) => {
  //   return total + parseInt(line.units) * parseFloat(line.price);
  // }, 0);

  const { createOrder, loading } = useCreateOrder();

  const { fields, append, remove } = useFieldArray({
    control: methods.control,
    name: "lines",
  });

  const handleOrder = async (data) => {
    const lines = data.lines.map((line) => {
      return {
        brandId: line.brand,
        itemId: line.item,
        quantity: parseFloat(line.units),
        price: parseFloat(line.price),
      };
    });

    const total = data.lines.reduce((total, line) => {
      return total + parseFloat(line.units) * parseFloat(line.price);
    }, 0);
    const order = {
      orderDate: data.orderDate,
      number: parseInt(data.orderNumber),
      totalAmount: parseFloat(total),
      status: "pending",
      vendorId: data.vendor,
      notes: data.notes,
      orderLines: lines,
      storeId: currentStore?.id,
    };

    await createOrder({
      variables: {
        input: order,
      },
    });
  };

  const addLine = (event) => {
    append({ brand: "", item: "", units: "", price: "", total: "" });
  };

  if (loading) {
    return <CircularProgress />;
  }

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(handleOrder)}>
        <Stack
          direction={"row"}
          sx={{
            marginTop: "4rem",
          }}
        >
          <Typography variant="h6" sx={{ flexGrow: 1 }}>
            Purchase Order{" "}
            <Button variant="contained">{currentStore?.name}</Button>
          </Typography>

          <Button type="submit" variant="contained" color="primary">
            Create order
          </Button>
        </Stack>
        <Grid gap={1} container>
          <Grid
            container
            sx={{
              margin: "10px auto",
              width: "100%",
            }}
          >
            <Grid item xs={8}>
              <VendorAutocomplete />
            </Grid>
            <Grid item xs={2}>
              <TextField name="orderNumber" label={"Order Number"} required />
            </Grid>
            <Grid item xs={2}>
              <DateField name="orderDate" label={"Order Date"} />
            </Grid>
          </Grid>

          {fields.map((field, index) => (
            <LineItem
              key={field.id}
              index={index}
              {...methods.register(`lineItem.${index}.value`)}
              onRemove={() => remove(index)}
            />
          ))}
          <Button
            variant="contained"
            onClick={addLine}
            startIcon={<AddCircleIcon color="primary" />}
            color="secondary"
          >
            Add Line
            {/* <AddCircleIcon marginRight={1} color="primary" />
            <Typography color="primary" paddingLeft={1} variant="label">
              Add Line
            </Typography> */}
          </Button>
        </Grid>
        <Divider sx={{ margin: "20px 0 40px 0" }} />
        <Stack
          direction="row"
          spacing={{ xs: 1, sm: 1 }}
          useFlexGap
          divider={<Divider orientation="vertical" flexItem />}
        >
          <MuiTextField
            name="notes"
            label={"Notes"}
            multiline
            rows={4}
            sx={{ width: "70%" }}
            {...methods.register("notes")}
          />
          <TableContainer>
            <Table aria-label="spanning table">
              <TableHead></TableHead>
              <TableBody>
                {/* <TableRow>
                  <TableCell colSpan={2}>Subtotal</TableCell>
                  <TableCell align="right">{Number(subTotal) || 0}</TableCell>
                </TableRow> */}
                {/* <TableRow>
                  <TableCell>Tax</TableCell>
                  <TableCell align="right">xyz</TableCell>
                  <TableCell align="right">xyz</TableCell>
                </TableRow> */}
                <TableRow>
                  <TableCell colSpan={2}>Total</TableCell>
                  <TableCell align="right">
                    {(Number(total) || 0).toFixed(2)}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Stack>
      </form>
    </FormProvider>
  );
}

export default Create;
