import "./App.css";
import { NotificationProvider } from "./context/Notification";
import { AuthProvider } from "./context/AuthContext";
import Routes from "./routes/Router";
import { ApolloProvider } from "./context/Apollo";

function App() {
  return (
    <NotificationProvider>
      <ApolloProvider>
        <AuthProvider>
          <Routes />
        </AuthProvider>
      </ApolloProvider>
    </NotificationProvider>
  );
}

export default App;
