import React, { useState } from "react";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Paper from "@mui/material/Paper";
import { visuallyHidden } from "@mui/utils";
import classNames from "classnames";

import css from "./styles.module.css";
import { Collapse } from "@mui/material";

function descendingComparator(a, b, orderBy) {
  if (b.cells[orderBy].rawValue < a.cells[orderBy].rawValue) {
    return -1;
  }
  if (b.cells[orderBy].rawValue > a.cells[orderBy].rawValue) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function EnhancedTableHead(props) {
  const { headCells, order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => () => {
    onRequestSort(property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align="left"
            sortDirection={orderBy === headCell.id ? order : false}
            sx={headCell.width ? { width: headCell.width } : undefined}
            className={classNames({ sticky: headCell.sticky })}
          >
            {headCell.label && (
              <>
                <TableSortLabel
                  active={orderBy === headCell.id}
                  direction={orderBy === headCell.id ? order : "asc"}
                  onClick={createSortHandler(headCell.id)}
                  sx={{
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                  }}
                >
                  {headCell.label}
                  {orderBy === headCell.id ? (
                    <Box component="span" sx={visuallyHidden}>
                      {order === "desc"
                        ? "sorted descending"
                        : "sorted ascending"}
                    </Box>
                  ) : null}
                </TableSortLabel>
              </>
            )}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

const pageSizes = [10, 25, 100];

function EnhancedTable({ custom, rows, headCells, mobileVariant }) {
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(pageSizes[1]);

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (_, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const orderedRows = orderBy
    ? rows.slice().sort(getComparator(order, orderBy))
    : rows;
  const pagedRows = orderedRows.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage
  );

  return (
    <Box>
      <TableContainer
        data-testid="table-container"
        component={Paper}
        sx={{ width: "100%", mb: 2 }}
      >
        <Table
          aria-labelledby="tableTitle"
          className={mobileVariant ? css.mobileColumn : undefined}
        >
          <EnhancedTableHead
            headCells={headCells}
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
          />
          <TableBody>
            {pagedRows.length > 0 ? (
              pagedRows.map((row, index) => (
                <TableRow
                  // data-testid="table-row"
                  tabIndex={-1}
                  key={row.key ?? index}
                  selected={row.selected}
                  className={classNames({
                    [css.collapsedRow]: row.collapsed,
                    // [css.proposal]: custom?.type === "proposal",
                  })}
                >
                  {Object.entries(row.cells).map(([key, cell]) => (
                    <TableCell
                      key={key}

                      className={classNames({
                        sticky: cell.sticky,
                        [css.collapsedCell]: row.collapsed,
                        
                      })}
                      style={{
                        padding: "10px"
                      }}
                    >
                      <Collapse key={index} in={!row.collapsed} enter={false}>
                        {cell.content}
                      </Collapse>
                    </TableCell>
                  ))}
                </TableRow>
              ))
            ) : (
              // Prevent no `tbody` rows hydration error
              <TableRow>
                <TableCell />
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>

      {
        rows.length > pagedRows.length &&
        <TablePagination
          rowsPerPageOptions={pageSizes}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      }
    </Box>
  );
}

export default EnhancedTable;
