import React, { useEffect, useState } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { Autocomplete, Chip, TextField, Modal , Box} from "@mui/material";
import Loader from "../../common/Loader";
import { useMasterCategories } from "./hooks/useMasterCategory";
import CreateMasterCategory from "./CreateMasterCategory";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

export function MasterCategoryAutocomplete() {
  const [options, setOptions] = useState([]);
  const [open, setOpen] = useState(false);
  const {
    control,
    formState: { errors },
  } = useFormContext();
  const { masterCategories, loading } = useMasterCategories();
  const handleClose = () => setOpen(false);

  useEffect(() => {
    if (masterCategories && masterCategories.length > 0) {
      setOptions([...masterCategories, { id: "add-new", name: "+ Add New" }]);
    } else {
      setOptions([{ id: "add-new", name: "+ Add New" }]);
    }
  }, [masterCategories, loading]);

  return (
    <>
      <Controller
        name="masterCategoryId"
        control={control}
        render={({ field }) => (
          <Autocomplete
            disablePortal
            freeSolo
            getOptionLabel={(option) => option.name || option}
            options={!loading && options ? options : []}
            onChange={(_, data) => {
              if (data?.id === "add-new") {
                // Handle the "Add New" option
                // You can open a dialog or perform any other action here
                // For example, you can open a dialog to create a new category
                setOpen(true);
              } else if (typeof data === "string") {
                // Handle the case where the user entered a new value
                field.onChange(data);
              } else {
                // Handle the case where the user selected an existing option
                field.onChange(data?.id);
              }
            }}
            renderOption={(props, option) => (
              <li {...props} key={option.id}>
                {option.id === "add-new" ? (
                  <Chip label="+ Add New" color="primary" />
                ) : (
                  option.name
                )}
              </li>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Master Category"
                error={Boolean(errors.masterCategoryId)}
                helperText={errors.masterCategoryId?.message}
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <React.Fragment>
                      {loading ? <Loader color="inherit" size={20} /> : null}
                      {params.InputProps.endAdornment}
                    </React.Fragment>
                  ),
                }}
              />
            )}
          />
        )}
        rules={{
          required: {
            value: true,
            message: "Master category is required*",
          },
        }}
      />
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="add-new-category-modal"
        aria-describedby="add-new-category-form"
      >
        <Box sx={style}>
          <h2 id="add-new-category-modal">Add New Category</h2>
          <CreateMasterCategory handleClose={handleClose} />
        </Box>
      </Modal>
    </>
  );
}

export default MasterCategoryAutocomplete;
