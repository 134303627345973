import React, { useRef } from "react";
import ModalDialog from "../../common/ModalDialog/index";
import {
  Box,
  Grid,
  List,
  Typography,
  Button,
  Stack,
} from "@mui/material";
import LeaderboardIcon from "@mui/icons-material/Leaderboard";
import Divider from "@mui/material/Divider";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import OrderTablePDF from "./OrderTablePDF";
import { formatDate } from "../../../utils/date";
import useAuthStore from "../../../store/authStore";

const OrderDetailsDialog = ({ handleClose, defaultValues }) => {
  const dialogRef = useRef();
  const { currentStore } = useAuthStore();
  const orderLines = defaultValues?.orderLines;

  console.log(currentStore);

  const exportAsPDF = async () => {
    const element = dialogRef.current;
    const canvas = await html2canvas(element);
    const imgData = canvas.toDataURL("image/png");
    const pdf = new jsPDF();
    const imgProps = pdf.getImageProperties(imgData);
    const pdfWidth = pdf.internal.pageSize.getWidth();
    const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
    pdf.addImage(imgData, "PNG", 0, 0, pdfWidth, pdfHeight);
    pdf.save("order-details.pdf");
  };

  return (
    <ModalDialog open onClose={handleClose} dialogTitle="Purchase Order">
      <Divider />
      <div ref={dialogRef}>
        <Grid container>
          <Grid
            item
            xs={12}
            display={"flex"}
            justifyContent={"space-between"}
            margin={1}
          >
            <Grid item display={"flex"} justifyContent={"flex-end"} xs={2}>
              <LeaderboardIcon color="primary" />
              <Typography variant="label">
                <strong>ORDERFLO</strong>
              </Typography>
            </Grid>

            <Grid item>
              <List
                style={{
                  display: "flex",
                  flexDirection: "column",
                  padding: "0",
                }}
              >
                <Typography
                  variant="caption"
                  sx={{
                    textAlign: "right",
                  }}
                >
                  Order Date :{" "}
                  <span>{formatDate(defaultValues?.orderDate)}</span>
                </Typography>
                <Typography
                  variant="label"
                  sx={{
                    textAlign: "right",
                  }}
                >
                  Purchase Order: <span>{defaultValues?.number}</span>
                </Typography>
              </List>
            </Grid>
          </Grid>
          <Stack
            width={"100%"}
            marginTop={3}
            marginBottom={3}
            padding={{
              xs: 1,
              sm: 1,
            }}
          >
            <Typography variant="caption" color="text.secondary.dark">
              PO NUMBER
            </Typography>
            <Box
              sx={{
                display: "flex",
                border: "1px solid #E5E5E5",
                padding: "5px",
                borderRadius: "10px",
              }}
            >
              <Typography variant="label">{defaultValues?.number}</Typography>
            </Box>
          </Stack>
          <Grid
            item
            xs={12}
            display={"flex"}
            gap={1}
            sx={{
              textAlign: "flex-start",
              marginBottom: "10px",
            }}
          >
            <Grid
              item
              xs={6}
              sx={{
                backgroundColor: "white",
                padding: "10px",
                borderRadius: "10px",
                border: "1px solid #E5E5E5",
              }}
            >
              <Typography variant="label">VENDOR</Typography>
              <List style={{ display: "flex", flexDirection: "column" }}>
                <Typography variant="caption">
                  {defaultValues.vendor.name}
                </Typography>
                {/* <Typography variant="caption">OCM#: {defaultValues}</Typography> */}
              </List>
            </Grid>
            <Grid
              item
              xs={6}
              sx={{
                backgroundColor: "white",
                padding: "10px",
                borderRadius: "10px",
                border: "1px solid #E5E5E5",
              }}
            >
              <Typography variant="label">SHIP TO</Typography>
              <List style={{ display: "flex", flexDirection: "column" }}>
                <Typography variant="caption">{currentStore?.name}</Typography>
                {/* <Typography variant="caption">{ defaultValues?.store?.address}</Typography> */}
                <Typography variant="caption">
                  BILL TO: {currentStore?.address}
                </Typography>
              </List>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <OrderTablePDF orderLines={orderLines} />
          </Grid>
        </Grid>
        <Stack
          direction="row"
          spacing={{ xs: 1, sm: 1 }}
          useFlexGap
          margin={1}
          sx={{
            backgroundColor: "white",
            borderRadius: "10px",
            border: "1px solid #E5E5E5",
            justifyContent: "space-between",
          }}
          divider={<Divider orientation="vertical" flexItem />}
        >
          <Stack
            sx={{
              padding: "10px",
            }}
          >
            <Typography variant="caption">Notes</Typography>
            <Typography variant="label">{defaultValues?.notes}</Typography>
          </Stack>
          <Stack
            sx={{
              padding: "10px",
            }}
          >
            <Typography variant="caption">Total</Typography>
            <Typography variant="label">
              {defaultValues?.totalAmount}
            </Typography>
          </Stack>
        </Stack>
      </div>

      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          margin: "10px",
        }}
      >
        <Button onClick={exportAsPDF} variant="contained" color="primary">
          Download PDF
        </Button>
      </Box>
    </ModalDialog>
  );
};

export default OrderDetailsDialog;
