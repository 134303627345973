import React from "react";
import { Controller, useFormContext } from "react-hook-form";
import { TextField } from "@mui/material";

function DateField({ name, label, rules }) {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <TextField
          {...field}
          type="date"
          label={label}
          variant="outlined"
          value={field.value}
          error={field.error}
          helperText={field.error?.message}
          onChange={(e) => {
            field.onChange(e.target.value);
          }}
          fullWidth
          InputLabelProps={{
            shrink: true,
          }}
        />
      )}
      rules={{
        required: {
          value: true,
          message: `${label} is required`,
        },
        rules,
      }}
    />
  );
}

export default DateField;
