import * as React from "react";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import ListSubheader from "@mui/material/ListSubheader";
import DashboardIcon from "@mui/icons-material/Dashboard";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import PeopleIcon from "@mui/icons-material/People";
import BarChartIcon from "@mui/icons-material/BarChart";
import LayersIcon from "@mui/icons-material/Layers";
import AssignmentIcon from "@mui/icons-material/Assignment";
import SettingsIcon from "@mui/icons-material/Settings";
import List2 from "./Navigation/Lists2";

export const mainListItems = (
  <React.Fragment>
    <List2
      menuItems={[
        {
          text: "Dashboard",
          icon: <DashboardIcon />,
          to: "/",
        },
        {
          text: "Orders",
          icon: <ShoppingCartIcon />,
          to: "/orders",
        },
        {
          text: "Lists",
          icon: <PeopleIcon />,
          subItems: [
            {
              text: "Vendors",
              to: "/vendors",
            },
            { text: "Items", to: "/items" },
            { text: "Categories", to: "/categories" },
            // { text: "Master Categories", to: "/mastercategories" },
            { text: "Brands", to: "/brands" },
          ],
        },
      ]}
    />
  </React.Fragment>
);

export const secondaryListItems = (
  <React.Fragment>
    <List2
      menuItems={[
        {
          text: "Reports",
          icon: <BarChartIcon />,
        },
        {
          text: "Integrations",
          icon: <LayersIcon />,
        },
        {
          text: "Settings",
          icon: <SettingsIcon />,
          to: "/settings",
        },
      ]}
    />
  </React.Fragment>
);
