import { useState, useMemo } from "react";
import Lists from "../../common/Lists";
import ListHeader from "../../common/Lists/ListsHeader";
import { defaultOpen, ModalType } from "../../../utils/contants/Modal/modal";
import { useBrands } from "../hooks/useBrands";
import CreateBrand from "./CreateBrand";
import BrandDetail from "./BrandDetail";
import ExportDialog from "../../common/Lists/ExportDialog";
import FullPageSpinner from "../../common/FullPage";

const coloumnsForCsvExport = ["name", "vendor"];

export default function BrandsList() {
  const { brands, loading } = useBrands();

  const [defaultValues, setDefaultValues] = useState(undefined);
  const [open, setOpen] = useState(defaultOpen);
  const [searchQuery, setSearchQuery] = useState("");
  const filteredEntries = useMemo(() => {
    if (!searchQuery) {
      return brands;
    }

    const query = searchQuery.toLowerCase();
    return brands.filter((brand) => {
      const { name } = brand;

      return name.toLowerCase().includes(query);
    });
  }, [brands, searchQuery]);

  const handleClose = () => {
    setOpen(defaultOpen);
    setDefaultValues(undefined);
  };
  const handleOpenModal = (type) => () => {
    setOpen((prev) => ({ ...prev, [type]: true }));
  };
  const handleOpenModalWithValues = (modal, data) => {
    setDefaultValues(data);
    handleOpenModal(modal)();
  };

  return (
    <>
      <ListHeader
        handleOpenModal={handleOpenModal}
        handleOpenModalWithValues={handleOpenModalWithValues}
        title="Brands"
        allListItems={brands ?? []}
        searchQuery={searchQuery}
        onSearchQueryChange={setSearchQuery}
      />
      {loading ? (
        <FullPageSpinner />
      ) : (
        <Lists
          handleViewDetail={(data) =>
            handleOpenModalWithValues(ModalType.DETAILS, { data })
          }
          loading={loading}
          list={filteredEntries ?? []}
          label="Brands"
        />
      )}

      {open[ModalType.ENTRY] && (
        <CreateBrand handleClose={handleClose} defaultValues={defaultValues} />
      )}

      {open[ModalType.DETAILS] && (
        <BrandDetail handleClose={handleClose} defaultValues={defaultValues} />
      )}

      {open[ModalType.EXPORT] && (
        <ExportDialog
          handleClose={handleClose}
          defaultValues={defaultValues}
          coloumnsForCsvExport={coloumnsForCsvExport}
          name="Brands"
        />
      )}
    </>
  );
}
